@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Roboto:wght@100;300;400;500;700&display=swap');

body {
    font-family: 'Franklin Gothic Book';
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 1.2px;
    color: #000;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.margin10 {
    margin-left: 10px;
}

.margin10Imp {
    margin-left: 10px !important;
}

.credits {
    text-align: right;
    margin-top: 15px;
}

.credits a {
    color: #222;
    background-color: #f8dc3d;
    font-family: "Segoe UI", "Ubuntu", monospace;
    font-weight: 500;
    text-decoration: none;
    padding: 2px 10px;
    border-radius: 4px;
}

.wrap,
#footer {
    width: 100%;
    float: left;
}

.topnav li a {
    color: #fff !important;
    cursor: pointer;
}

.wrap ul {
    list-style: none;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    float: left;
    width: 100%;
    flex-direction: row-reverse;
}

.wrap ul li {
    float: left;
    width: 6%;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}

.wrap ul li:hover,
.wrap ul li:first-child~li:last-child {
    width: 100%;
    opacity: 1;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.wrap ul li:hover~li:last-child {
    width: 100px;
}

.jseffect {
    float: left;
}

/** Add cool filter efects **/
.wrap ul li:nth-child(1)::before {
    background-color: rgba(255, 87, 34, 0.2);
}

.wrap ul li:nth-child(2)::before {
    background-color: rgba(76, 175, 80, 0.25);
}

.aligncenter {
    text-align: center;
}

.wrap ul li:nth-child(3)::before {
    background-color: rgba(33, 150, 243, 0.25);
}

.wrap ul li:nth-child(4)::before {
    background-color: rgba(156, 39, 176, 0.2);
}

.wrap input:checked~.wrap ul li::before {
    width: 100%;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.wrap ul li .businessfooter {
    opacity: 0;
}

.wrap ul li:hover .businessfooter,
.wrap ul li:hover,
.wrap ul li:first-child~li:last-child .businessfooter {
    opacity: 1;
}

.wrap ul li:hover~li:last-child .businessfooter {
    opacity: 0;
}

.wrap ul li:nth-child(7) .business,
.business:nth-child(2) {
    background: #76cac2;
}

.wrap ul li:nth-child(6) .business,
.business:nth-child(3) {
    background: #f2ac91;
}

.wrap ul li:nth-child(5) .business,
.business:nth-child(4) {
    background: #f08080;
}

.wrap ul li:nth-child(4) .business,
.business:nth-child(5) {
    background: #8a66aa;
}

.wrap ul li:nth-child(3) .business,
.business:nth-child(6) {
    background: #b5e1f3;
}

#header {
    height: 140px;
}

@keyframes slow-move-anticlockwise {
    0% {
        -webkit-transform: rotate(0deg) translateX(-5px) rotate(0deg);
        transform: rotate(0deg) translateX(-5px) rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(-360deg) translateX(-10px) rotate(357deg);
        transform: rotate(-360deg) translateX(-10px) rotate(357deg);
    }

    100% {
        -webkit-transform: rotate(-720deg) translateX(-5px) rotate(720deg);
        transform: rotate(-720deg) translateX(-5px) rotate(720deg);
    }
}

.slow-move-anticlockwise {
    -webkit-animation: slow-move-anticlockwise 15s cubic-bezier(.41, .17, .57, .79) infinite both;
    animation: slow-move-anticlockwise 15s cubic-bezier(.41, .17, .57, .79) infinite both;
}

.col-md-10.phone span {
    margin-left: 15px;
}

.col-12.jseffect .business {
    width: 75px;
}

.col-12.jseffect .business.active {
    width: 75%;
}

.col-12.jseffect .business .businessfooter {
    opacity: 0;
}

.col-12.jseffect .business.active .businessfooter {
    opacity: 1;
    visibility: visible;
}

.bannercont.pulll-center {
    margin: 0;
}

.banner:before {
    content: '';
    background: rgb(0 0 0 / 2%);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.bannercont.pulll-center h2 {
    display: inline-block;
}

.service {
    position: relative;
}

.p-0.textcenter .banner {
    align-items: end;
    padding-bottom: 25px;
}

.topstrip ul li a.active {
    font-weight: normal;
}

.topstrip ul li a.active {
    font-weight: normal;
}

.highlightpoint ul li {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #de3b17;
    font-family: 'Franklin Gothic Medium Cond';
    font-weight: bold;
    letter-spacing: 1px;
    width: 25%;
}

.highlightpoint ul li span {
    width: 100%;
    float: left;
    font-family: 'Franklin Gothic Book';
    color: #fff;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
}

.highlightpoint {
    border-top: solid 1px #626262;
    padding-top: 25px;
}

.partners-logo {
    display: flex;
    margin: 0px auto;
    width: 100%;
    justify-content: center;
    float: left;
}

.businesslogo img {
    width: 15%;
}

/* Deepak code start */
.addMore {
    margin-top: 10px;
    margin-right: 10px;
    float: right;
}

.addMore:nth-child(1) {
    margin-right: 0;
}

.multiLogo {
    display: flex;
    justify-content: space-between;
}

/* Deepak Code End */
.overview,
.value {
    width: 100%;
    float: left;
}

.journey,
.overview,
.ethos,
.value {
    margin-top: 50px;
}

.ethoscont h3 span.color-black {
    color: #000;
}

.proslider .slogo {
    height: 200px;
    position: relative;
    border: solid 1px #ededed;
    width: 90%;
    margin-left: 5%;
    overflow: hidden;
}

.slick-slide:hover img {
    transform: scale(1.1) translate(-50%, -50%);
}

.leadershipcont span {
    width: 100%;
    float: left;
}

.businessbody .col-md-6:nth-child(3) .busilabel:before,
.businessbody .col-md-6:nth-child(4) .busilabel:before {
    opacity: 0;
}

.dot-img {
    z-index: -1;
    right: -14%;
    width: 23%;
}

.topnav li {
    padding-left: 40px;
}

.topstrip {
    background: #ed1a3b;
    line-height: 25px;
}

ul.d-flex.navbar {
    padding: 0;
}

.videostn img:first-child {
    height: 86vh;
    width: auto;
}

.topnav li a {
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    letter-spacing: 2px;
}

.navigation {
    margin: 20px 0;
}

.menu {
    width: 90%;
    float: right;
}

.navbar li a {
    font-size: 15px;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.videostn img:last-child {
    margin-top: 70px;
    margin-right: 70px;
}

.videocont {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    display: flex;
    width: auto;
}

.videocont h2 {
    margin: 0;
    width: 59%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 212px;
    color: #fff;
    letter-spacing: 22px;
}

.videocont h3 {
    width: max-content;
    margin: 0;
    display: grid;
}

.videocont h3 span {
    font-family: Playfair Display, serif;
    font-size: 58px;
    font-weight: 400;
    line-height: 64px;
    width: 100%;
}

section {
    padding: 80px 0 40px;
    width: 100%;
    float: left;
}

#video {
    padding: 0;
    overflow: hidden;
}

.quote h2 {
    width: 100%;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-size: 38px;
    line-height: 48px;
    color: #000;
    margin-bottom: 30px;
}

.quote h3 {
    font-weight: normal;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 24px;
    margin-top: 15px;
    color: #231f20;
}

.quote h3 span {
    color: #6d6e71;
    font-size: 15px;
    letter-spacing: 3px;
    text-transform: capitalize;
}

.down-arrow {
    position: absolute;
    right: 0;
    bottom: 2%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.down-arrow span {
    width: 50px;
    height: 100px;
    float: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.down-arrow span:before {
    content: '';
    width: 2px;
    height: 100px;
    background: #ed1a3b;
    float: left;
    cursor: pointer;
}

.bg-circle {
    width: 130px;
    height: 130px;
    position: absolute;
    background: #e0e0e0;
    border-radius: 100%;
    left: 2%;
    opacity: 0.3;
}

.sm-circle {
    width: 25px;
    height: 25px;
    position: absolute;
    background: #e0e0e0;
    border-radius: 100%;
    left: 8%;
    opacity: 0.3;
    top: 7.5em;
}

.web-container {
    width: 72.35%;
    margin: 0px auto;
}

.title {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 35px;
    padding-bottom: 20px;
    position: relative;
}

.title h2,
.title h1 {
    margin: 0;
    font-size: 20px;
    font-family: 'Gotham';
    font-weight: 300;
    letter-spacing: 10px;
    text-transform: uppercase;
}

.title:before {
    content: '';
    width: 67px;
    height: 3px;
    background: #d12017;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.heading h1 {
    font-size: 60px;
    line-height: 70px;
    display: grid;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    margin-bottom: 25px;
}

.heading h1 em {
    color: #e14417;
}

.content ul li,
p {
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 1.2px;
}

.mbox a:hover span {
    color: #b3acac;
}

.btn-3 {
    display: inline-block;
    position: relative;
    background: none;
    cursor: pointer;
    border-bottom: solid 1px #231f20;
    transition: ease all 0.5s;
    text-decoration: none;
    margin-top: 20px;
}

.btn-3::before {
    right: 0;
    top: 0;
    transition-delay: 0.2s;
}

.btn-3::before,
.btn-3::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    transition: all 0.2s linear;
    background: #0a0e13;
}

.btn-3 span::before,
.btn-3 span::after {
    content: "";
    width: 1px;
    height: 50%;
    position: absolute;
    transition: all 0.2s linear;
    background: #ffffff;
    right: 0;
    top: 50%;
    transform: translateY(0%);
}

.btn-3 span::before {
    transition-delay: 0s;
    left: 0;
    background: #231f20;
}

.btn-3:hover::after {
    transition-delay: 0.0s;
}

.btn-3:hover span::after {
    transition-delay: 0.2s;
}

.btn-3:hover::before {
    transition-delay: 0.4s;
}

.btn-3:hover span::before {
    transition-delay: 0.6s;
}

.btn-3:hover::before,
.btn-3:hover::after {
    width: 100%;
}

.btn-3:hover span::before,
.btn-3:hover span::after {
    height: 100%;
    background: #0a0e13;
    transform: inherit;
}

.btn-3 span {
    display: block;
    font-size: 15px;
    transition: ease all 0.5s;
    letter-spacing: 2px;
    pointer-events: all;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    background-image: linear-gradient(to right, #d32517, #d32517 50%, #231f20 50%);
    transform: translateY(0);
    transition: transform 1s 1s, background-position 1s, ease all 0.5s;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    padding: 5px 20px;
}

.contactbox .btn-3 span {
    text-transform: lowercase;
}

.btn-3::after {
    left: 0;
    bottom: 0;
}

.btn-3:hover {
    border-color: transparent;
    text-decoration: none;
}

.btn-3:hover span::before {
    top: 0;
}

.btn-3:hover span::after {
    bottom: 0;
    top: 0;
    opacity: 1;
}

.whitebtn .btn-3 span::before,
.whitebtn .btn-3 span::after,
.whitebtn .btn-3::before,
.whitebtn .btn-3::after {
    content: '';
    background: #fff;
}

.whitebtn .btn-3 {
    border-color: #fff;
}

.whitebtn .btn-3 span {
    background-image: linear-gradient(to right, #ffffff, #ffffff 50%, #ffffff 50%);
}

.btn-3:hover span {
    background-position: 0;
}

.btn-3 span::after {
    opacity: 0;
}

.numberbanner {
    background: url(./image/number-banner.webp);
    background-size: cover;
    padding: 100px 0;
    background-attachment: fixed;
    background-position: center;
}

.App {
    width: 18%;
    color: #fff;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 25px 1%;
    height: 150px;
}

.whitebtn .btn-3::after {
    bottom: -1px;
}

.App:before {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    left: -3%;
    background: #de3b17;
    transform: rotate(18deg);
}

.countnum,
.countnum h3 {
    font-size: 50px;
    font-family: 'Playfair Display', serif;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    font-weight: normal;
    margin: 0;
}

.countnum p {
    margin: 0;
    text-transform: capitalize;
}

.labelcont {
    font-size: 17px;
    line-height: 27px;
    width: 96%;
    float: left;
    height: 70px;
    margin-top: 10px;
    font-family: 'Franklin Gothic Book';
    text-transform: uppercase;
    letter-spacing: 4px;
}

.App:first-child:before,
.App:nth-child(6):before,
.App:nth-child(11):before {
    opacity: 0;
}

p.space {
    padding-left: 10px;
}

.count {
    justify-content: center;
}

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    transition: ease all 0.5s;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.logoslider p {
    width: 40%;
    margin: 0 auto;
    text-align: center;
}

.slick-slider button {
    position: absolute;
    border: none;
    font-size: 0;
    width: 50px;
    background: #b6b6b8;
    height: 50px;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.slick-slider button.slick-arrow.slick-prev:before,
.slick-slider button.slick-arrow.slick-next:before {
    background: url(./image/harrow.webp);
    content: '';
    position: absolute;
    width: 70%;
    height: 60%;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: cover;
}

.proslider {
    margin-top: 50px;
}

.slick-slider button.slick-arrow.slick-next {
    right: -75px;
}

.slick-slider button.slick-arrow.slick-prev {
    left: -75px;
}

.slick-slider button.slick-arrow.slick-next:before {
    transform: translate(-50%, -50%) rotate(180deg);
    top: 54%;
}

.slick-slider button:hover {
    background: #db3617;
}

.dot-img-left {
    top: 25%;
    z-index: -1;
    left: -14%;
    width: 23%;
    transform: rotate(180deg);
}

.dot-img-left img {
    width: 100%;
    height: auto;
}

.slogo {
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

.newsroom {
    background: #e6e7e8;
    padding: 50px 0;
}

.newsimg img {
    float: right;
}

.newsroombox {
    display: flex;
    margin-bottom: 10px;
}

.newsroomimg {
    width: 8%;
}

.newsroomcont {
    width: 94%;
}

.newsroomcont p {
    margin: 0;
}

.newsroomcont span {
    font-size: 15px;
    color: rgb(0 0 0 / 50%);
    letter-spacing: 0.6px;
}

.mbox {
    width: 200px;
    position: relative;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
    margin: 2%;
    padding: 0;
}

.mbox img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: grayscale(1);
}

.mbox a:before {
    content: '';
    background: rgb(0 0 0 / 80%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.mbox span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: auto;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 1px;
    z-index: 2;
}

.footer {
    width: 96%;
    float: right;
    background: #58595b;
    padding: 75px;
    border-radius: 100px 0px 0 0px;
    position: relative;
    overflow: hidden;
    padding-top: 5em;
    padding-bottom: 2em;
    margin-top: 80px;
}

.footer-container {
    width: 75%;
    margin-left: 6%;
}

.about,
.copyright {
    width: 100%;
    float: left;
}

.about h3 {
    color: #ed1a3b;
    text-transform: uppercase;
    font-size: 30px;
    letter-spacing: 1px;
    font-family: 'Franklin Gothic Medium Cond';
}

.about p {
    width: 85%;
}

.footer span a {
    text-decoration: none;
    color: #fff;
}

.footer h4 {
    white-space: nowrap;
    color: #fff;
    text-transform: uppercase;
    font-size: 21px;
    font-family: 'Franklin Gothic Medium Cond';
    letter-spacing: 1px;
}

.footer p {
    color: #fff;
    line-height: 28px;
    margin: 0;
    font-size: 17px;
}

.copyright ul li {
    padding: 0 10px;
    display: flex;
    border-right: solid 1px #8a8b8d;
}

.copyright ul li:last-child {
    border: none;
}

.copyright ul li a {
    font-size: 14px;
    color: #b8b8b9 !important;
    text-decoration: none;
    line-height: 20px;
    letter-spacing: 0.5px;
}

.copyright {
    margin-top: 4em;
    border-top: solid 1px #8a8b8d;
    position: relative;
    z-index: 1;
    padding: 10px 0;
}

.copyright ul li a:hover {
    color: #fff !important;
}

.cicleimg {
    position: absolute;
    left: -10%;
    bottom: 0;
}

.dotimg {
    position: absolute;
    right: 0;
    bottom: 0;
}

.ml-40 {
    margin-left: 40px;
}

.banner {
    height: 480px;
    display: flex;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.banner .container,
.banner .container .row {
    height: 100%;
    float: left;
    width: 100%;
    max-width: 100%;
}

.bannercont {
    width: 100%;
    float: left;
    display: flex;
    align-items: end;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
}

.bannercont h2 {
    color: #fff;
    margin: 0;
    display: grid;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 35px;
    line-height: 50px;
}

.bannercont h2 em {
    color: #ed1a3b;
}

.react-tabs__tab-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 55px;
}

.react-tabs__tab-list li,
.react-tabs__tab-list span {
    margin: 0 50px;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 3.5px;
    border-bottom: solid 1px #000;
    padding: 0 20px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
}

.react-tabs__tab-list li:before,
.react-tabs__tab-list span:before {
    content: '';
    width: 1px;
    height: 50%;
    position: absolute;
    background: #000;
    left: 0;
    bottom: 0;
}

.react-tabs__tab-list li[aria-selected="true"] {
    color: #e14417;
    font-weight: 600;
}

.ethosbanner {
    position: relative;
}

.ethosstn {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    color: #fff;
    width: 70%;
}

.ethosbanner .title {
    margin-bottom: 100px;
}

.ethoscont h3 {
    color: #ce1817;
    font-size: 32px;
    line-height: 46px;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
    margin-bottom: 20px;
    float: left;
}

.ethoscont h3 span {
    width: 100%;
    float: left;
    text-transform: initial;
    color: #fff;
    font-style: italic;
}

.ethoscont {
    font-size: 16px;
    line-height: 34px;
}

.ethoscont p {
    margin: 0;
}

.ethoscont h4 {
    font-style: italic;
    display: inline-block;
    font-family: 'Playfair Display', serif;
    width: 100%;
    font-size: 16px;
    margin: 0;
}

.ethoscont ul li {
    width: 100%;
    float: left;
    position: relative;
    letter-spacing: 1px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    line-height: 24px;
}

.ethoscont ul li em {
    width: 100%;
    float: left;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 26px;
}

.ethoscont ul li:before {
    content: '';
    width: 30px;
    height: 2px;
    background: #dc3917;
    position: absolute;
    bottom: 0;
    left: 0;
}

.ethoscont ul li:last-child:before {
    opacity: 0;
}

.values {
    margin-top: 80px;
    background: #e6e7e8;
    padding: 5em 0 10em;
    position: relative;
}

.values .dot-img {
    top: 0;
}

.heading h4 {
    margin: 0;
    font-size: 33px;
    color: #ed1a3b;
    font-family: 'Playfair Display';
    font-weight: normal;
    line-height: 50px;
    text-transform: uppercase;
}

.heading h3 {
    margin: 15px 0 0 0;
    font-size: 72px;
    display: grid;
    font-family: 'Franklin Gothic Demi';
    color: #ed1a3b;
    line-height: 72px;
    text-transform: uppercase;
    font-weight: bold;
}

.slidepostion {
    position: relative;
    top: -5em;
    left: 10em;
}

.vcontent {
    position: relative;
    margin: 50px 0;
}

.valueslider img {
    position: static;
    transform: inherit;
    width: auto;
}

.vcontent h3 {
    font-family: Franklin Gothic Demi;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 30px;
    margin: 0 0 10px;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
}

.ethoscont.text-center.col-12.d-inline-block h3 {
    margin: 0;
}

.vcontent h3:before {
    content: '';
    width: 50px;
    height: 3px;
    background: #ed1a3b;
    position: absolute;
    bottom: 0;
}

.vcontent p {
    line-height: 26px;
    margin: 25px 0 0 0;
    display: inline-block;
}

.valueslider button {
    display: none !important;
}

.journeybox {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    /* height: 55vh; */
}

.journeycircle {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 200px 0;
}

.journeycircle h5 {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5831f;
    border-radius: 100%;
    font-size: 45px;
    line-height: 45px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    color: #fff;
    margin: 0;
}

.jslider {
    width: 90%;
    margin: 3em 10%;
    float: left;
    margin-bottom: 0;
}

.journey {
    float: left;
    width: 100%;
}

.journeycontent p {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
    position: relative;
    padding-left: 22px;
}

.journeycontent {
    width: 80%;
    float: right;
    display: flex;
    justify-content: end;
    margin-left: 10%;
    height: 150px;
    align-items: end;
    position: absolute;
}

.journeycontent:before {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background: #ff390c;
    border-radius: 100%;
    top: 0;
    left: -3px;
}

.journeycontent:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background: #ff390c;
    border-radius: 100%;
    bottom: 0;
    left: -3px;
}

.journeycontent p:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: #ff390c;
    left: 0;
}

.jslider .slick-slide:nth-child(odd) .journeybox .journeycontent {
    bottom: 0;
}

.jslider .slick-slider {
    display: flex;
    align-items: center;
    justify-content: center;
}

.journeybox:before {
    content: '';
    position: absolute;
    width: 100%;
    background: #f5831f;
    top: 50%;
    height: 2px;
    z-index: -1;
}

.businessbox,
.businessview,
.business,
.businessbanner,
.businesscont a {
    float: left;
    width: 100%;
}

.business:nth-child(7) {
    background: #c6f1a5;
}

.business:nth-child(8) {
    background: #f7ad82;
}

.business:nth-child(9) {
    background: #88b1ee;
}

.business h3 {
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    float: left;
    transform: rotate(-90deg);
    position: absolute;
    width: 65px;
    bottom: 8%;
    white-space: nowrap;
    margin: 0;
}

.businessbanner img {
    width: 100%;
    object-fit: cover;
}

.businesscont {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 95%;
    opacity: 0;
    transition-delay: 0.5s;
    transition-duration: 1s;
    visibility: hidden;
}

.col-12.jseffect .business.active .businesscont {
    opacity: 1;
    visibility: visible;
}

.labelcont em {
    font-style: inherit;
}

.businessview:before {
    content: '';
    background: rgb(0 0 0 / 60%);
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
}

.businesslogo {
    width: 90%;
    float: left;
    margin-left: 5%;
}

.businessbody {
    float: left;
    width: 70%;
    position: relative;
    color: #fff;
    margin-left: 15%;
    margin-top: 5%;
}

.businessbody .col-md-6:nth-child(3) .busilabel,
.businessbody .col-md-6:nth-child(4) .busilabel {
    margin-bottom: 0;
    padding-bottom: 0;
}

.click span {
    padding-right: 40px;
}

.businessfooter {
    float: left;
    width: 100%;
    background: rgb(0 0 0 / 20%);
    position: absolute;
    bottom: 0;
    padding: 25px 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    visibility: hidden;
    left: 0;
}

.busilabel h4 {
    margin: 0;
    display: grid;
    font-size: 17px;
    line-height: 27px;
    width: 100%;
    float: left;
    font-family: 'Franklin Gothic Book';
    text-transform: uppercase;
    letter-spacing: 4px;
}

.busilabel {
    float: left;
    width: 100%;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 15px;
}

.busilabel h4 span {
    font-size: 36px;
    width: 100%;
    float: left;
    line-height: 36px;
    margin-top: 5px;
    font-family: 'Franklin Gothic Medium Cond';
    letter-spacing: 1px;
}

.businessfooter h4 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    width: 90%;
    letter-spacing: 5px;
}

.click {
    text-align: right;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
    width: auto;
    margin-right: 40px;
}

.click:after {
    content: '';
    background-image: url(./image/click.webp);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;

}

.ethos {
    background-image: url(./image/our-ethos.webp);
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    float: left;
}

.businessview {
    transition: ease all 1s;
    margin-left: 70px;
}

.business {
    position: relative;
    width: 100%;
    background: #fcdfa7;
    margin: 0 5px;
    transition: ease all 1s;
    overflow: hidden;
    cursor: pointer;
}

.business.active .businessview {
    opacity: 1;
}

.business.active {
    width: 75%;
}

.businessbanner,
.businessview,
.businessbanner img {
    height: 550px;
}

.businessbox {
    z-index: 1;
    margin: 20px 0 0px 0;
}

.leaderships {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.leadership2 {
    width: 48%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0 0;
    align-items: center;
    filter: grayscale(1);
}

.leadershipcont {
    width: 55%;
    float: left;
}

.leadershipcont h2 {
    margin: 0;
    font-size: 24px;
    letter-spacing: 1px;
    font-family: 'Franklin Gothic Medium Cond';
    text-transform: uppercase;
}

.leadershipcont ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    text-transform: uppercase;
}

.leadershipcont ul li {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    margin: 2px 0;
    width: 100%;
}

.leadershipcont ul li:first-child {
    font-weight: 500;

}

.leadership2 .btn-3 span {
    font-size: 13px;
    padding: 0 15px;
    line-height: 30px;
}

.leadershipcont ul li:last-child {
    border: none;
}

.leadershipimg {
    width: 40%;
    float: left;
    overflow: hidden;
    position: relative;
}

.leadership2:nth-child(1),
.leadership2:nth-child(2) {
    flex-wrap: wrap;
}

.leadership2:nth-child(1) .leadershipimg,
.leadership2:nth-child(2) .leadershipimg {
    width: 100%;
}

.leadership2:nth-child(1) .leadershipcont,
.leadership2:nth-child(2) .leadershipcont {
    width: 100%;
    margin: 25px 0;
}

/* 
.leadership2:nth-child(1) .leadershipcont h2,
.leadership2:nth-child(2) .leadershipcont h2 {
    font-family: 'Playfair Display', serif;
} */
.leadership2:hover {
    filter: none;
}

.leadership2:hover img {
    transform: scale(1.1);
}

.leadershipimg img {
    width: 100%;
    transition: ease all 0.5s;
    transform: scale(1);
}

.leadership .col-md-4 {
    margin-bottom: 1.5em;
}

.leadershipcont h3 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-bottom: solid 2px #ed1a3b;
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-family: 'Franklin Gothic Demi';
    font-weight: 900;
}

.leadershipcont h4 {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    height: 40px;
    line-height: 20px;
}

.csrcont {
    width: 100%;
    margin: 0px auto;
}

.csr_descp {
    margin-top: 50px;
}

.csr_descp .row {
    margin: 50px 0 0 0;
}

.csr_descp .row img {
    width: 100%;
}

.csr_descp .row:nth-child(even) {
    flex-direction: row-reverse;
}

.csrdecp h3 {
    font-size: 30px;
    line-height: 40px;
    font-family: 'Playfair Display', serif;
}

.service .col-md-3.col-sm-6.col-xs-12 {
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0;
}

.service span {
    font-size: 17px;
    line-height: 24px;
    width: 90%;
    margin: 15px 5% 0;
    height: 75px;
}

.entertainmentdtl h2,
.entertainmentdtl h1 {
    font-size: 19px;
    font-family: 'Gotham';
    font-weight: 300;
    letter-spacing: 7px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.entertainmentdtl h3 {
    font-family: 'Franklin Gothic Demi';
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 1.2px;
}

.entertainmentdtl ul li {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 6px;
}

.entertainmentdtl ul li:before {
    content: '';
    width: 33px;
    height: 3px;
    background: #ef063d;
    position: absolute;
    bottom: 0;
}

.entertainmentdtl ul li:last-child:before {
    opacity: 0;
}

.entertainmentdtl ul {
    margin-bottom: 20px;
}

.snapshot .numberbanner .countnum span {
    padding-right: 5px;
}

.entertainmentlogo {
    width: 250px;
    height: 250px;
    border: solid 1px #e1e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
}

.entertainmentlogo img {
    width: 75%;
}

.csrdecp p {
    margin: 0;
}

.career .row:nth-child(even) {
    flex-direction: inherit;
}

.career .row:nth-child(odd) {
    flex-direction: row-reverse;
}

.accordion {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}

.accordion .accordion-item:first-child .title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion .accordion-item:last-child .title {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.accordion .accordion-item .title {
    background-color: #f6f6f6;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    letter-spacing: 0.6px;
    letter-spacing: 2px;
    font-size: 18px;
}

.accordion .accordion-item .title:after {
    content: '\2795';
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}

.accordion .accordion-item .panel {
    max-height: 0;
    -webkit-transition: max-height 0.15s ease-out;
    -o-transition: max-height 0.15s ease-out;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}

.accordion .accordion-item.active .title {
    background-color: #eee;
}

.accordion .accordion-item.active .title:after {
    content: '\2796';
}

.accordion .accordion-item.active .panel {
    max-height: 500px;
    -webkit-transition: max-height 0.25s ease-in;
    -o-transition: max-height 0.25s ease-in;
    transition: max-height 0.25s ease-in;
}

.accordion {
    float: left;
    width: 100%;
    border: none;
    padding: 0;
}

.accordion-item {
    float: left;
    width: 100%;
}

.accordion .accordion-item.active .title {
    background: transparent;
}

.accordion .accordion-item.active .title:before {
    opacity: 0;
}

.accordion .accordion-item .panel {
    float: left;
    width: 100%;
}

.accordion-item {
    border: none;
}

.accordion .accordion-item.active .title:after {
    content: "\2212";
}

.accordion .accordion-item .title:after {
    content: "\002B";
    font-size: 36px;
    float: right;
    font-weight: 300;
    color: #000;
}

.accordion .accordion-item .title {
    margin-bottom: 10px;
    background: transparent;
    padding: 0;
    margin: 0;
}

.accordion .accordion-item .title:before {
    opacity: 0;
}

.accordion-item {
    margin: 0;
    border-top: 2px solid #000 !important;
    padding: 10px 0;
    border-radius: 0 !important;
}

.careerlink {
    margin-top: 20px;
}

.navbar li a.active,
.topnav li a.active {
    font-weight: 600;
}

@keyframes MoveUpDown {

    0%,
    100% {
        bottom: 0;
    }

    50% {
        bottom: 5px;
    }
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.header.fixed {
    width: 100%;
    z-index: 3;
    box-shadow: 0 3px 3px rgb(0 0 0 / 12%);
}

.header {
    background: #fff;
    float: left;
    width: 100%;
}

.animated {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

@-webkit-keyframes bounce {

    0%,
    100% {
        -webkit-transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-20px)
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-20px)
    }
}

.hemburgur span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #000;
    transition: .5s ease-in-out;
    float: left;
    position: absolute;
}

.hemburgur {
    display: none;
    width: 40px;
    height: 30px;
    cursor: pointer;
    transform: rotate(0deg);
    position: relative;
    float: right;
    z-index: 9;
    right: 0;
    background: transparent;
    border: none;
    padding: 0;
    outline: 0;
}

.hemburgur span:nth-child(1) {
    top: 0;
}

.hemburgur span:nth-child(2) {
    top: 12px;
}

.hemburgur span:nth-child(3) {
    top: 24px;
}

.mobile-show {
    display: none;
}

.desktop-show {
    display: block;
}

.click a {
    text-decoration: none;
    color: #fff;
    float: left;
    transition: ease all 0.5s;
    white-space: nowrap;
    padding-right: 40px;
}

.click:hover a {
    opacity: 0.8;
}

.descript {
    position: absolute;
    top: -100vh;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0/85%);
    padding: 15px;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    overflow-x: auto;
    visibility: hidden;
    color: #fff;
}

.descpthight::-webkit-scrollbar-track {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0 0 0 4px;
}

.descpthight::-webkit-scrollbar-thumb {
    background-color: #ed1a3b;
}

.descpthight::-webkit-scrollbar-corner {
    background: transparent;
}

.descpthight::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}

.descpthight::-webkit-scrollbar {
    overflow: visible;
    width: 7px;
    padding: 0;
    background: #222;
}

.descript p {
    line-height: 24px;
    margin-bottom: 8px;
    letter-spacing: 0.3px;
}

.leadershipbox:hover .descript {
    visibility: visible;
    display: block;
    opacity: 1;
    top: 0;
}

.leadershipbox {
    overflow: hidden;
    position: relative;
}

.bannercont.pulll-right {
    width: 100%;
    align-items: center;
    justify-content: end;
    display: flex;
    margin: 0;
}

.quote {
    width: 60%;
    margin: 0px auto;
}

.mbox a {
    height: 100%;
    float: left;
    width: 100%;
    cursor: pointer;
}

.jslider .slick-slider .slick-list .slick-track .slick-slide.slick-current:nth-child(1) .journeybox:before {
    content: '';
    width: 70%;
    right: 0;
}

.journeybox.last-child:before {
    content: '';
    width: 50%;
    left: 0;
}

button.slick-arrow.slick-next.slick-disabled,
button.slick-arrow.slick-prev.slick-disabled {
    opacity: 0.2;
}

.descpthight {
    position: absolute;
    left: 15px;
    bottom: 15px;
    right: 0px;
    top: 15px;
    overflow-x: auto;
    padding: 10px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

div#notfound {
    width: 100%;
    float: left;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notfound {
    width: 50%;
    text-align: center;
}

.notfound h2 {
    margin: 0;
}

.notfound h1 {
    margin: 0;
}

div#notfound a.active {
    color: #9f8468;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}

.videocont h3 span em {
    color: #ed1a3b;
}

.aboutcont {
    margin-top: 25px;
    width: 100%;
}

.ethos.col-12 {
    margin-bottom: 50px;
}

.partners-logo ul {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.partners-logo ul li {
    margin: 15px 2.5%;
    width: 15%;
}

.partners-logo ul li img {
    width: 100%;
    filter: none;
    transition: ease all 0.5s;
}

.partners-logo ul li:hover img {
    filter: grayscale(0);
}

.contactdetails ul {
    width: 100%;
}

.contactdetails ul li:nth-child(1) {
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    position: relative;
}

.contactdetails ul li strong {
    width: 25%;
    font-family: Franklin Gothic Demi;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    line-height: 20px;
}

.contactdetails ul li {
    display: flex;
    align-items: center;
}

.contactdetails ul li p a {
    text-decoration: none;
    color: #fff;
}

.contactdetails ul li:nth-child(1):before {
    content: '';
    width: 37px;
    height: 2px;
    background: #ed1a3b;
    position: absolute;
    bottom: 0;
}

.socialmedia,
.contactdetails {
    width: 70%;
    margin: 0px auto;
}

.service p {
    width: 100%;
    margin: 10px 0;
}

.busilabel:before {
    content: '';
    width: 37px;
    height: 3px;
    background: #ed1a3b;
    position: absolute;
    bottom: 0;
}

.contactdetails ul li p a:hover {
    color: #e85217;
}

.breadcrum ul {
    padding: 10px 0;
    border-bottom: 1px solid #d1d4d6;
    margin-top: 15px;
}

.breadcrum ul li {
    padding-right: 10px;
    position: relative;
    padding-left: 20px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;

    font-weight: 500;
    line-height: 20px;
}

.breadcrum ul li:before {
    content: '>';
    position: absolute;
    left: 0;
}

.breadcrum ul li a {
    color: #ed1a3b;
    text-decoration: none;
}

.breadcrum ul li:first-child {
    padding-left: 0;
}

.breadcrum ul li:first-child:before {
    opacity: 0;
}

.leaddetails {
    width: 100%;
    float: left;
}

.leaddetails img {
    filter: grayscale(1);
    transition: .2s;
}

.leaddetails img:hover {
    filter: none;
}

.leaddetails h1 {
    font-size: 41px;
    font-family: Playfair Display, serif;
    margin: 0;
    line-height: 45px;
    letter-spacing: 1px;
    font-weight: normal;
    border-left: 5px solid #ed1a3b;
    padding-left: 30px;
}

.leaddetails h2 {
    font-size: 24px;
    line-height: 50px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #ed1a3b;
    font-family: Playfair Display, serif;
    border-left: 5px solid #ed1a3b;
    padding-left: 30px;
}

.leaddetail {
    margin-top: 40px;
}

.leaddetail img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    float: left;
    margin-right: 30px;
    position: relative;
    margin-bottom: 10px;
}

.leaddetail .btn-3 {
    margin-top: 60px;
}


.contactdetail {
    border-bottom: 1px solid #ededed;
    display: flex;
    float: left;
    justify-content: space-between;
    margin: 50px 5% 0;
    padding-bottom: 35px;
    width: 90%;
}



.contactbox ul li:last-child:before {
    opacity: 0;
}

.contactbox ul li:last-child {
    padding: 0;
    margin: 0;
}

.contactbox {
    width: 33%;
    float: left;
}

.contactbox .aligncenter {
    text-align: left;
}

.contactbox ul li {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 20px;
    position: relative;
}

.contactbox ul {
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.contactbox ul li strong {
    float: left;
    width: 100px;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 21px;
    font-family: 'Franklin Gothic Medium Cond';
    letter-spacing: 2px;
    font-weight: 400;
}

.address {
    width: 60%;
    float: right;
}

.contactbox ul li p {
    margin: 0;
}

.contactbox ul li p a {
    text-decoration: none;
    color: #000;
}

/* .contactbox ul li:before {
    content: '';
    width: 37px;
    height: 2px;
    background: #ed1a3b;
    position: absolute;
    bottom: 0;
} */

.contactbox h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: 24px;
    font-family: 'Franklin Gothic Medium Cond';
    letter-spacing: 3px;
    font-weight: 500;
    color: #ed1a3b;
    width: 100%;
}

.contactbox p {
    margin: 0;
}

.contactdetail:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.bglayerbox {
    width: 200px;
    height: 200px;
    position: absolute;
    transition: ease all 1s;
}

.bglayerbox:before {
    content: '';
    background-image: url(./image/circles.webp);
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    animation-name: rotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.img02 {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    transform: translate(-50%, -50%) scale(1);
    transition: ease all 2s;
}

.bglayerbox:hover .img02 {
    transform: translate(-50%, -50%) scale(1.2);
}

.businessassoc {
    position: relative;
    width: 100%;
    float: left;
    margin: 50px 0;
}

.bg-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.business-associates {
    float: left;
    width: 100%;
    padding: 75px 0;
}

.bglayerbox:nth-child(1) {
    top: 5%;
    left: 4%;
}

.bglayerbox:nth-child(2) {
    top: 38%;
    left: 17%;
}

.bglayerbox:nth-child(3) {
    top: 0%;
    left: 33%;
}

.bglayerbox:nth-child(4) {
    bottom: -15%;
    left: 67%;
}

.bglayerbox:nth-child(5) {
    left: 60%;
    top: 0;
}

.bglayerbox:nth-child(6) {
    left: 45%;
    top: 35%;
}

.bglayerbox:nth-child(7) {
    right: 0%;
    bottom: 5%;
}

.bglayerbox:nth-child(8) {
    right: 15%;
    bottom: 35%;
}

.bglayerbox:nth-child(9) {
    right: 0;
}

.bglayerbox:nth-child(10) {
    bottom: 0;
}

.bglayerbox:nth-child(11) {
    bottom: 0%;
    left: 35%;
}

.img01 {
    animation-name: rotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(-360deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.5, 1.5);
    }

    100% {
        transform: scale(1, 1);
    }
}

.ycircle {
    width: 30px;
    height: 30px;
    background: #ffac00;
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    animation: zoom-in-zoom-out 9s ease-out infinite;
}

.circle01 {
    top: 42%;
    left: 25%;
}

.circle02 {
    left: 28%;
    bottom: 18%;
}

.circle03 {
    left: 50%;
    top: 40%;
    width: 10px;
    height: 10px;
}

.circle04 {
    left: 46%;
    bottom: 24%;
    width: 18px;
    height: 18px;
}

.circle05 {
    right: 33%;
    top: 49%;
    width: 20px;
    height: 20px;
}

.circle06 {
    bottom: 28%;
    right: 24%;
    width: 22px;
    height: 22px;
}

.circle07 {
    bottom: 7%;
    right: 4%;
    width: 60px;
    height: 60px;
}

.circle08 {
    bottom: 40%;
    right: 16%;
    width: 14px;
    height: 14px;
}

.backbtn .btn-3 {
    margin-right: 30px;
}

.backbtn .btn-3 span i:before {
    float: left;
}

.mid-container {
    width: 88%;
    margin: 0px auto;
}

.countnum sup {
    font-size: 17px;
    top: -1em;
}

.business-associates p {
    width: 70%;
    margin: 0px auto;
}

.smap {
    width: auto;
    float: left;
    display: flex;
    margin: 50px 0;
    margin-left: 10%;
}

.sitembox {
    width: 30%;
}

.sitembox ul {
    margin: 0;
    float: left;
    width: 100%;
}

.stxmn,
.stxmns {
    position: relative;
    width: 100%;
    float: left;
}

.stxmn a:before {
    position: absolute;
    top: 20px;
    left: 0.2%;
    content: "";
    background: #ec1f28;
    width: 8px;
    height: 8px;
    z-index: 1;
}

.stxmn a {
    color: #ec1f28;
    font-size: 18px;
    line-height: 24px;
    padding: 0 20px;
    margin: 10px 0;
    width: 100%;
    float: left;
    text-decoration: none;
}

.stxbdr {
    width: 100%;
    float: left;
    position: relative;
}

.stxbdr:before {
    position: absolute;
    top: -20.5px;
    left: 4px;
    content: "";
    height: 93%;
    width: 2px;
    background: #d2d1d1;
}

.stxbdr:after {
    position: absolute;
    bottom: 21px;
    left: 4px;
    content: "";
    height: 93%;
    width: 2px;
    background: #d2d1d1;
}

.stxmns a:before {
    display: none;
}

.stxmn .stxmns a {
    color: #565656;
    font-size: 15px;
    width: 100%;
    float: left;
    padding: 0 5px;
}

.stxmn .stxmns a img {
    margin-right: 10px;
    opacity: 0.5;
    margin-top: -3px;
    width: 23px;
}

.stemap {
    width: 100%;
    float: left;
}

.content {
    width: 60%;
    margin: 0px auto;
}

.contactbox ul li:nth-child(3) {
    align-items: baseline;
}

.ethoscont p {
    width: 100%;
    float: left;
}

.backbtns i.backarrow {
    background-image: url(./image/white-arrow.webp);
}

.busicon {
    display: none !important
}

.busicon {
    content: '';
    background-image: url(./image/white-arrow.webp);
    width: 20px;
    height: 20px;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 2%;
    opacity: 0.5;
    transition: ease all 0.5s;
}

.busicon:nth-child(3) {
    content: '';
    background-image: url(./image/white-arrow.webp);
    right: 2%;
    transform: rotate(180deg);
    left: auto;
}

.business:nth-child(1) .busicon:nth-child(1),
.business:nth-child(8) .busicon:nth-child(3) {
    opacity: 0;
}

.busicon:hover {
    opacity: 1;
}

i.backarrow {
    background-image: url(./image/left-arrow.webp);
    height: 15px;
    width: 22px;
    background-size: contain;
    margin-right: 8px;
    background-repeat: no-repeat;
    position: relative;
    top: 6px;
    display: inline-block;
    opacity: 0.8;
}

.business:nth-child(4) .busilabel:before,
.business:nth-child(5) .busilabel:before,
.business:nth-child(6) .busilabel:before,
.business:nth-child(7) .busilabel:before,
.business:nth-child(8) .busilabel:before,
.business:nth-child(9) .busilabel:before {
    opacity: 0;
}

.contactdetail:nth-child(2) .contactbox {
    width: 100%;
}

.contactdetail:nth-child(2) .contactbox ul {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    width: 100%;
    height: auto;
}

.contactdetail:nth-child(2) .contactbox ul li {
    flex-wrap: wrap;
    width: 33%;
    margin: 0;
    padding: 0;
}

.contactdetail:nth-child(2) .contactbox ul li strong {
    width: 100%;
    margin-bottom: 10px;
}

.contactdetail:nth-child(2) .contactbox ul li:before {
    opacity: 0;
}

.contactdetail:nth-child(2) .contactbox h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 36px;
}

.content ul {
    list-style: inherit;
    padding-left: 30px;
}

.content ul li {
    margin-bottom: 1rem;
}

.content h3 {
    font-weight: 600;
    margin-top: 70px;
}

.content .row {
    margin: 0;
}

.backbtns {
    position: absolute;
    left: 2%;
    bottom: 0;
}

.backbtns .btn-3 span {
    background-image: linear-gradient(to right, #ffffff, #ffffff 50%, #ffffff 50%);
    display: flex;
    align-items: center;
}

.backbtns .btn-3::before,
.backbtns .btn-3::after,
.backbtns .btn-3 span::before,
.backbtns .btn-3 span::after {
    background: #fff;
    content: '';
    bottom: inherit;
}

.backbtns .btn-3 {
    border-color: #fff;
}

.csrimg ul {
    display: flex;
    justify-content: space-between;
}

.csrimg ul li {
    width: 24%;
    position: relative;
}

.csrimg ul li:before {
    content: '';
    background: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
}

.csrimg ul li span {
    color: #fff;
    font-family: Franklin Gothic Demi;
    position: absolute;
    text-align: center;
    width: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.csrimg ul li img {
    width: 100%;
}

.csrimg {
    margin: 25px 0 25px 0;
}

.csrcont h4 {
    margin: 50px 0 30px 0;
    letter-spacing: 1px;
    font-family: 'Franklin Gothic Demi';
}

.pomotionimg {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
}

.pomotionimg li {
    width: 24%;
}

.contactbox img {
    width: 150px;
    height: 150px;
    margin: 15px 0;
}

.justify-space-between {
    justify-content: space-between;
    display: flex;
}

li.stxmns a.active {
    cursor: default;
}

.banner.entertainment:before {
    opacity: 0;
    content: '';
}

.businessview.position-relative.media:before {
    opacity: 0;
}

.backbtns a.btn-3 {
    margin: 0;
}

.backbtns i.backarrow {
    top: 0;
    margin: 0;
}

.socialmedia {
    margin-top: 20px;
    padding-top: 15px;
    border-top: solid 1px #777777;
}

.socialmedia ul li {
    float: left;
    width: 30px;
    opacity: 0.5;
    transition: ease all 0.5s;
    margin-right: 5px;
}

.socialmedia ul li a,
.socialmedia ul li a span {
    display: inline-block;
}

.socialmedia ul li:hover {
    opacity: 1;
}

.countwrap {
    display: flex;
    align-items: center;
}

.numberbanner.col-12 .web-container {
    width: 90%;
}

.App:nth-child(5) .countnum {
    padding-left: 90px;
}

.real-est-ex-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.real-est-ex-logo img {
    width: 12%;
    float: unset;
    margin-right: 10px !important;
    padding: 2px;
}

.real-est-ex-logo img:last-child {
    margin-right: 0 !important;
}

/* Responsive starts here */
#shadow-host-companion {
    padding: 0;
}

.evc-document {
    padding: 50px 0;
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.evc-document .overview {
    margin: 0;
}

.evc-document .web-container {
    width: 100%;
}



@media (min-width:1440px) {
    .container {
        max-width: 87%;
    }

}

@media (max-width:1680px) {
    .busilabel h4 {
        font-size: 16px;
        line-height: 22px;
    }

    .busilabel h4 span {
        font-size: 32px;
        line-height: 32px;
    }

    .businessfooter h4 {
        font-size: 19px;
    }

    .businessfooter {
        padding: 15px;
    }

    .videostn img:last-child {
        margin-top: 20px;
        margin-right: 20px;
    }

    .videocont h3 span {
        font-size: 50px;
        line-height: 60px;
    }

    .videostn img:first-child {
        width: 85%;
        height: auto;
    }

    body {
        font-size: 19px;
        line-height: 34px;
    }

    .cicleimg img {
        width: 250px;
    }

    .about h3 {
        font-size: 26px;
    }

    .footer h4 {
        font-size: 22px;
    }

    .csrdecp h3 {
        font-size: 32px;
        line-height: 38px;
    }

    .accordion .accordion-item .title:after {
        font-size: 28px;
    }

    .journeycontent {
        height: 100px;
    }

    .journeycircle h5 {
        height: 120px;
        width: 120px;
        font-size: 38px;
        line-height: 46px;
    }

    .slick-slider button.slick-arrow.slick-prev:before,
    .slick-slider button.slick-arrow.slick-next:before {
        width: 80%;
        height: 70%;
    }

    .quote h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .title h2,
    .title h1 {
        font-size: 18px;
    }

    .web-container {
        width: 80%;
    }

    .proslider .slogo {
        height: 160px;
    }

    .logo {
        width: 100%;
        float: left;
    }

    .about .logo img {
        width: 60%;
    }

    .navbar li a {
        letter-spacing: 5px;
    }

    .business {
        margin: 0 2px;
    }
}

@media (max-width:1600px) {
    .accordion .accordion-item .title:after {
        font-size: 28px;
    }

    .business {
        margin: 0 2px;
    }

    .busilabel h4 {
        font-size: 15px;
        letter-spacing: 3px;
    }

    .videocont h2 {
        font-size: 160px;
    }

    .mbox {
        width: 175px;
        height: 175px;
    }

    .mbox span {
        font-size: 16px;
        line-height: 20px;
    }

    .videocont h3 span {
        font-size: 50px;
        line-height: 60px;
    }

    .quote h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .quote h3 {
        font-size: 16px;
        line-height: 24px;
    }

    .heading h1 {
        font-size: 60px;
        line-height: 70px;
    }

    .title h2,
    .title h1 {
        letter-spacing: 5px;
    }

    .title:before {
        width: 60px;
        height: 2px;
    }

    .countnum,
    .countnum h3 {
        font-size: 50px;
    }


    .App {
        margin: 25px 0.5%;
    }

    body {
        font-size: 17px;
        line-height: 30px;
    }

    .footer h4 {
        font-size: 21px;
    }

    section {
        padding: 70px 0 30px;
    }

    .logo a {
        display: inline-block;
    }

    .logo a img {
        width: 90%;
    }

    .vcontent h3:before {
        width: 80px;
        height: 3px;
    }

    .heading h3 {
        font-size: 60px;
        line-height: 66px;
    }

    .heading h4 {
        font-size: 28px;
    }

    .dotimg {
        width: 30%;
    }

    .cicleimg img {
        width: 230px;
    }

    .leadershipcont h3 {
        font-size: 14px;
    }

    .leadershipcont h4 {
        font-size: 13px;
    }

    .labelcont {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 2.5px;
        width: 96%;
    }

    .ethoscont h3 {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 15px;
    }

    .ethosbanner .title {
        margin-bottom: 50px;
    }

    .navigation {
        margin: 10px 0;
    }

    #header {
        height: 112px;
    }

    .business-associates p {
        width: 80%;
    }

    .entertainmentdtl h2 {
        font-size: 17px;
    }
}

@media (max-width:1440px) {
    .col-12.jseffect .business {
        width: 52px;
    }

    .click a:before {
        width: 32px;
        height: 32px;
        top: 3px;
    }

    .highlightpoint ul li {
        font-size: 18px;
        line-height: 26px;
    }

    .csrdecp h3 {
        font-size: 26px;
        line-height: 34px;
    }

    .btn-3 span {
        font-size: 13px;
        letter-spacing: 1px;
    }

    .csr_descp {
        margin-top: 0;
    }

    section {
        padding: 60px 0 25px;
    }

    .footer {
        padding: 60px;
    }

    .cicleimg img {
        width: 200px;
    }

    .banner {
        height: 400px;
    }

    .csrcont {
        width: 80%;
    }

    .footer h4 {
        font-size: 19px;
    }

    .web-container {
        width: 90%;
    }

    .labelcont {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 2px;
    }

    .logoslider p {
        width: 50%;
    }

    .App:before {
        left: -3%;
    }

    .ethosstn .row.col-12.m-0 {
        width: 90%;
        margin-left: 10% !important;
    }

    .ethoscont h3 {
        font-size: 26px;
        line-height: 36px;
    }

    .vcontent {
        margin: 25px 0;
    }

    .vcontent p {
        margin: 0;
    }

    .heading h1 {
        font-size: 50px;
        line-height: 60px;
    }

    .App {
        height: 100px;
    }

    .bannercont h2 {
        font-size: 36px;
        line-height: 44px;
    }

    .mbox {
        height: 175px;
        width: 175px;
    }

    .mbox span {
        font-size: 15px;
        line-height: 20px;
    }

    .vcontent h3 {
        font-size: 18px;
        line-height: 30px;
    }

    .vcontent h3:before {
        width: 60px;
        height: 2px;
    }

    .heading h3 {
        font-size: 50px;
        line-height: 56px;
    }


    .entertainmentdtl h3 {
        font-size: 17px;
        line-height: 24px;
    }

    .entertainmentdtl ul li:before {
        width: 30px;
        height: 2px;
    }

    .service .col-md-3.col-sm-6.col-xs-12 {
        margin: 25px 0;
    }

    .service span {
        margin-top: 10px;
    }

    .business h3 {
        font-size: 28px;
        margin: 0;
        bottom: 7%;
        left: -8px;
    }

    .businessview {
        margin-left: 50px;
    }

    .businessfooter {
        padding: 10px;
    }

    .business {
        margin: 0 2px;
    }

    .busilabel {
        margin-bottom: 10px;
    }

    .businessbody {
        width: 80%;
    }

    .businessfooter h4 {
        font-size: 17px;
    }

    .videostn img:last-child {
        margin-right: 25px;
    }

    .videocont h3 span {
        font-size: 40px;
        line-height: 50px;
    }

    .wrap ul li:hover~li:last-child {
        width: 70px;
    }

    .quote h2 {
        font-size: 26px;
        line-height: 36px;
    }

    .busilabel h4 span {
        font-size: 30px;
        line-height: 30px;
    }

    .col-md-10.phone span {
        margin-left: 10px;
    }

    .navbar li a {
        font-size: 13px;
        letter-spacing: 4px;
    }

    .title h2,
    .title h1 {
        font-size: 16px;
        letter-spacing: 4px;
    }

    .title {
        margin-bottom: 25px;
        padding-bottom: 15px;
    }

    .title:before {
        width: 50px;
    }

    .content ul li,
    p,
    .highlightpoint ul li span {
        margin-bottom: 5px;
        line-height: 28px;
        letter-spacing: 1px;
    }

    .heading {
        width: 70%;
        margin-left: 30%;
    }

    .socialmedia,
    .contactdetails {
        width: 80%;
        float: left;
    }

    .socialmedia ul li {
        width: 25px
    }

    .overview .heading {
        width: 100%;
        float: left;
        margin: 0;
    }

    .bglayerbox {
        width: 175px;
        height: 175px;
    }

    .journeycontent p {
        font-size: 16px;
        line-height: 20px;
    }

    .businessbanner,
    .businessview,
    .businessbanner img {
        height: 400px;
    }

    .click:after {
        width: 25px;
        height: 25px;
    }

    .click {
        margin-right: 25px;
    }

    .copyright ul li a {
        font-size: 12px;
        letter-spacing: 0.8px;
    }

    .footer p {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.8px;
    }
}

@media (max-width:1366px) {
    .logo a img {
        width: 80%;
    }

    .col-md-2.ml-40 {
        margin-left: 20px;
    }

    .quote h3 {
        font-size: 14px;
        line-height: 20px;
    }

    .col-md-10 span {
        margin-left: 15px;
    }

    .slick-slider button {
        width: 35px;
        height: 35px;
    }

    .quote {
        width: 60%;
    }

    .entertainmentdtl h2 {
        font-size: 15px;
        letter-spacing: 5px;
    }


    .entertainmentdtl ul {
        margin-bottom: 10px;
    }

    .service span {
        width: 100%;
        margin: 10px 0 0 0;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

    .vcontent h3 {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 8px;
        margin-bottom: 8px;
    }

    .ethosbanner .title {
        margin-bottom: 50px;
    }

    .ethoscont h3 {
        font-size: 19px;
        line-height: 26px;
    }

    .ethoscont ul li {
        padding-bottom: 8px;
        margin-bottom: 8px;
        font-size: 13px;
    }

    .videostn img:last-child {
        width: 100px;
        margin-top: 50px;
    }

    .down-arrow {
        bottom: 5%;
    }

    .videocont h2 {
        font-size: 140px;
        letter-spacing: 16px;
    }

    .title h2,
    .title h1 {
        font-size: 14px;
    }

    .title:before {
        width: 50px;
    }

    .heading h1 {
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 0;
    }

    .footer span a {
        font-size: 16px;
        white-space: nowrap;
        line-height: 20px;
    }

    .about h3 {
        font-size: 24px;
    }

    .mbox {
        width: 150px;
        height: 150px;
    }

    .mbox span {
        font-size: 14px;
        line-height: 18px;
    }

    .content ul li,
    p,
    .vcontent p,
    .highlightpoint ul li span {
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.8px;
    }

    .footer p {
        line-height: 22px;
        font-size: 13px;
    }

    .descript p {
        font-size: 14px;
        line-height: 20px;
    }

    .react-tabs__tab-list li {
        font-size: 13px;
        margin: 0 20px;
    }

    .react-tabs__tab-list {
        margin-bottom: 50px;
    }

    .businessbox {
        margin: 25px 0;
    }

    .business,
    .businessbanner,
    .businessview,
    .businessbanner img {
        height: 350px;
    }

    .business h3 {
        font-size: 22px;
        left: -10px;
    }

    .busilabel h4 span {
        font-size: 24px;
        line-height: 28px;
    }

    .businessbody {
        width: 70%;
    }

    .busilabel h4 {
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 20px;
    }

    .businessfooter h4 {
        font-size: 16px;
        letter-spacing: 3px;
    }

    .quote h2 {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 10px;
    }

    .quote h3 {
        margin: 10px 0;
    }

    .quote h3 span {
        font-size: 13px;
        letter-spacing: 1.5px;
    }

    .quote h3 {
        letter-spacing: 1px;
    }

    .dot-img-left img,
    .dot-img img {
        width: 80%;
    }

    .newsroomcont span {
        font-size: 13px;
        letter-spacing: 0.5px;
    }

    section {
        padding: 50px 0 20px;
    }

    .title {
        padding-bottom: 15px;
        margin-bottom: 25px;
    }

    img.location {
        height: 30px;
    }

    img.mail {
        width: 28px;
        margin-left: 10px;
    }

    .footer-container .col-md-5.col-sm-6.col-xs-12.d-flex {
        margin-top: 5px;
    }

    .journeycontent p {
        font-size: 15px;
        letter-spacing: 0.5px;
        padding-left: 10px;
    }

    .jslider {
        width: 80%;
        margin: 3em 10%;
    }

    .entertainmentlogo {
        width: 200px;
        height: 200px;
    }

    .entertainmentlogo img {
        width: 60%;
    }

    .banner {
        height: 330px;
    }

    .descpthight {
        padding-top: 0;
        padding-bottom: 0;
    }

    .descript p {
        margin-bottom: 5px;
    }

    .bannercont h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .videostn img:first-child {
        height: 85vh;
        object-fit: cover;
        object-position: top;
    }

    .logoslider .container {
        width: 80%;
    }

    .ethos {
        height: 82vh;
    }

    .labelcont {
        font-size: 12px;
        line-height: 20px;
    }

    .countnum,
    .countnum h3 {
        font-size: 45px;
    }

    .numberbanner {
        padding: 50px 0;
    }

    .partners-logo ul {
        width: 70%;
    }

    .leadershipcont h3 {
        letter-spacing: 1px;
    }

    #header {
        height: 100px;
    }

    .click a:before {
        width: 26px;
        height: 26px;
        top: 6px;
    }

    .bglayerbox {
        width: 150px;
        height: 150px;
    }

    .circle01 {
        top: 41%;
        left: 26%;
    }

    .circle02 {
        left: 29%;
        bottom: 17%;
    }

    .circle04 {
        left: 48%;
    }

    .circle06 {
        right: 20%;
    }

    .circle05 {
        right: 29%;
    }

    .circle03 {
        left: 52.5%;
    }

    .breadcrum ul {
        margin-top: 10px;
    }

    .breadcrum ul li {
        padding-left: 15px;
        padding-right: 5px;
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .leadershipcont h2 {
        font-size: 20px;
        line-height: 20px;
    }

    .leadershipcont ul li {
        font-size: 12px;
        letter-spacing: 1.5px;
        margin: 0;
    }

    .leadershipcont ul {
        margin-top: 5px;
    }

    .leadership2:nth-child(1) .leadershipcont,
    .leadership2:nth-child(2) .leadershipcont {
        margin: 15px 0 0 0;
    }

    .footer {
        margin-top: 50px;
    }

    .leaddetails h1 {
        font-size: 34px;
        line-height: 40px;
        padding-left: 25px;
    }

    .leaddetails h2 {
        font-size: 20px;
        line-height: 40px;
        padding-left: 25px;
    }

    .contactbox h2 {
        font-size: 20px;
        letter-spacing: 2px;
    }

    .contactbox ul li:before {
        height: 1px;
    }

    .contactbox ul li strong {
        font-size: 18px;
    }

    .contactdetail {
        margin-top: 20px;
    }

    .click:after {
        width: 20px;
        height: 20px;
        top: 5px;
    }

    .click span {
        padding-right: 30px;
        letter-spacing: 2px;
        font-size: 12px;
    }

    .businessbody {
        margin-top: 2%;
    }

    .click {
        margin-right: 20px;
    }

    .contactbox img {
        width: 100px;
        height: 100px;
        margin: 10px 0;
    }

    .contactbox ul {
        height: 60px;
    }

    .contactdetail:nth-child(2) .contactbox h2 {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .contactdetail:nth-child(2) .contactbox ul li strong {
        margin-bottom: 5px;
    }

    .backbtns i.backarrow {
        width: 10px;
        height: 10px;
        margin-right: 5px;
    }

    .backbtns .btn-3 {
        margin-top: 10px;
    }

    .backbtns .btn-3 span {
        padding: 0 10px;
        font-size: 12px;
    }

    .entertainmentdtl h2,
    .entertainmentdtl h1 {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 5px;
    }

    .contactdetails ul li:nth-child(1) {
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .contactdetails ul li:nth-child(1):before {
        width: 30px;
        height: 1px;
    }
}

@media (max-width:1280px) {
    .slogo {
        height: 150px;
    }

    .ethos {
        height: 55vh;
    }


    .slick-slider button {
        width: 40px;
        height: 40px;
    }

    .videostn img:first-child {
        height: auto;
    }

    .journey,
    .overview,
    .ethos,
    .value {
        margin-top: 20px;
    }

    .business-associates {
        padding: 50px 0 20px 0;
    }

    .footer-container {
        width: 80%;
    }

    .businessfooter h4 {
        font-size: 14px;
        letter-spacing: 2.5px;
    }

    .menu {
        width: 100%;
    }

    .highlightpoint ul li {
        font-size: 17px;
    }

    .navbar li a {
        font-size: 12px;
        letter-spacing: 3px;
    }

    .contactdetail {
        width: 100%;
        margin: 10px 0;
    }

    .bglayerbox {
        height: 120px;
        width: 120px;
    }
}

@media (max-width:1190px) {

    .videocont h3 span {
        font-size: 36px;
        line-height: 40px;
    }

    .numberbanner {
        padding: 25px 0;
    }

    .countnum,
    .countnum h3 {
        font-size: 36px;
    }

    .web-container {
        width: 90%;
    }

    .dot-img-left img,
    .dot-img img {
        width: 65%;
    }

    .logoslider p {
        width: 60%;
        line-height: 20px;
    }

    .slidepostion {
        left: 0;
    }

    .busilabel h4 span {
        font-size: 20px;
    }

    .businessfooter {
        padding: 5px;
    }

    .businessfooter h4 {
        font-size: 14px;
        letter-spacing: 1px;
    }

    .click {
        font-size: 12px;
    }


    .business h3 {
        font-size: 20px;
    }

    .quote h2 {
        font-size: 22px;
        letter-spacing: 0.5px;
        line-height: 30px;
    }

    .heading h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .business-associates p {
        width: 90%;
    }

    .footer h4 {
        font-size: 17px;
    }

    .copyright ul li a {
        font-size: 12px;
    }

    .copyright ul li {
        padding: 0 5px;
    }

    .copyright {
        margin-top: 3em;
    }

    .labelcont {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    .business,
    .businessbanner,
    .businessview,
    .businessbanner img {
        height: 320px;
    }

    .busilabel {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .busilabel h4 {
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 16px;
    }
}

@media (max-width:1040px) {
    .footer h4 {
        font-size: 15px;
    }


    .valueslider img {
        width: 100%;
        height: 100%;
    }

    .heading h3 {
        font-size: 46px;
        line-height: 46px;
    }

    .col-md-2.ml-40 {
        margin-left: 15px;
    }

    .footer-container .col-md-8.col-sm-6.col-xs-12.d-flex {
        padding: 0;
    }

    img.mail {
        margin-left: 5px;
        width: 20px;
    }

    img.location {
        height: 24px;
    }

    .footer span {
        margin-left: 0;
    }

    .footer span a {
        font-size: 15px;
    }

    .about h3 {
        font-size: 20px;
    }

    .footer {
        padding: 30px;
        width: 98%;
    }

    .copyright ul {
        padding: 10px 0;
    }

    .copyright ul li a {
        font-size: 12px;
    }

    .title h2,
    .title h1 {
        font-size: 13px;
        line-height: 20px;
    }

    .btn-3 span {
        font-size: 12px;
    }

    section {
        padding: 50px 0;
    }

    .heading h1 {
        font-size: 40px;
        line-height: 50px;
        padding-left: 50px;
    }

    .banner {
        height: 250px;
    }

    .bannercont h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .react-tabs__tab-list li {
        margin: 0 10px;
    }

    .csr_descp .row {
        margin: 25px 0;
    }

    .accordion .accordion-item .title {
        font-size: 14px;
    }

    .topnav li {
        padding-left: 20px;
    }

    .topnav li a {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .topstrip {
        line-height: 20px;
    }

    .heading h1 {
        padding: 0;
    }

    .breadcrum ul li {
        font-size: 10px;
    }

    .breadcrum ul {
        padding: 5px 0;
    }

    .partners-logo ul {
        width: 90%;
    }

    .leaddetails h1 {
        font-size: 24px;
        line-height: 30px;
    }

    .leaddetails h2 {
        font-size: 18px;
        line-height: 24px;
    }

    .csrdecp h3 {
        letter-spacing: 0.5px;
        font-size: 24px;
        line-height: 30px;
    }

}

@media (max-width:960px) {
    .businessbody {
        width: 80%;
    }

    .menu {
        width: 100%;
    }

    .csrimg ul li,
    .pomotionimg li {
        width: 49%;
        margin-bottom: 2%;
    }

    .csrimg ul,
    .pomotionimg {
        flex-wrap: wrap;
    }

    .journeycircle {
        margin: 170px 0;
    }

    .journey .container {
        max-width: 96%;
    }

    .evc-document {
        min-height: unset;
    }

}

@media (max-width:800px) {

    .menu,
    .businesscont {
        width: 100%;
    }

    .businessbody {
        width: 90%;
        margin-left: 5%;
    }

    .businessview {
        margin-left: 40px;
    }

    .videocont h3 {
        color: #000;
    }



    .businessfooter h4 {
        font-size: 10px;
    }

    .business h3 {
        font-size: 17px;
        line-height: 40px;
        width: 60px;
    }

    .topnav li {
        padding-left: 25px;
    }

    .heading h1 {
        margin: 0;
    }

    .heading h1 {
        padding-left: 0;
    }

    .videostn img:first-child {
        width: 100%;
    }

    .videocont h3 span {
        font-size: 30px;
    }

    .videocont h2 {
        font-size: 120px;
        letter-spacing: 10px;
    }

    .videostn img:last-child {
        margin-top: 25px;
    }

    .numberbanner.col-12 .web-container,
    .web-container {
        width: 100%;
    }

    .App:nth-child(5) .countnum {
        padding: 0;
    }

    .labelcont {
        font-size: 12px;
        line-height: 16px;
    }

    .App {
        margin: 15px 0;
        width: 30%;
    }

    #moreabout,
    #logoslider {
        padding-top: 0;
    }

    .videostn.d-flex.align-items-start.justify-content-around {
        display: block;
    }

    .videostn img:last-child {
        position: absolute;
        right: 0;
        bottom: 30%;
    }

    #logoslider {
        padding-top: 0;
    }

    .count.d-flex.flex-wrap {
        width: 100%;
        justify-content: center;
    }

    .App:first-child:before,
    .App:nth-child(6):before {
        opacity: 1;
    }

    .sm-circle {
        left: 20%;
    }
}

@media (max-width:768px) {
    .proslider .slogo {
        height: 120px;
    }

    .videocont h3 span,
    .videocont h3 span em {
        color: #fff;
    }

    .about .logo img {
        width: 150px;
    }

    .footer {
        padding: 0;
        padding-top: 35px;
    }

    .footer-container {
        width: 86%;
        margin-left: 14%;
    }

    .cicleimg {
        left: -20%;
    }


    .contactdetails {
        margin-right: 20%;
    }

    .logoslider p {
        width: 80%;
    }

    .proslider {
        margin-top: 25px;
    }

    .mobile-show {
        display: block;
    }

    .desktop-show,
    h2.desktop-show,
    .circles {
        display: none;
    }

    .journeycontent {
        width: 80%;
    }

    .header {
        padding: 10px 0;
    }

    .col-12.jseffect .business {
        width: 32px;
    }

    .heading h1 {
        display: table-footer-group;
        font-size: 34px;
        line-height: 40px;
    }

    .menu ul.d-flex.navbar li {
        padding: 10px 0;
    }

    .numbers.snapshot .App:before {
        height: 100%;
        width: 1px;
        opacity: 1;
    }

    .menu ul.d-flex.navbar li a {
        font-size: 30px;
        color: #fff;
        font-family: 'Playfair Display', serif;
        line-height: 50px;
        text-transform: capitalize;
        letter-spacing: 1px;
    }

    .hemburgur {
        display: block;
    }

    .entertainmentlogo {
        width: 150px;
        height: 150px;
        margin: 0px auto;
    }

    .snapshot .App:nth-child(odd):before {
        opacity: 1;
    }

    .snapshot .App:before {
        left: 0;
    }


    .menu.active .hemburgur span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 15px;
        left: 0;
        width: 80%;
        height: 2px;
    }

    .menu.active .hemburgur span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 15px;
        left: 0;
        width: 80%;
        height: 2px;
    }

    .menu.active .hemburgur span:nth-child(2) {
        opacity: 0;
    }

    ul.d-flex.navbar {
        position: fixed;
        background: rgb(65 64 66/90%);
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        text-align: center;
        display: inline-block !important;
        top: -100vh;
        height: 100vh;
        padding-top: 20vh;
        transition: ease all 0.5s !important;
    }

    .menu.active ul.d-flex.navbar {
        opacity: 1;
        visibility: visible;
        top: 0;
    }

    .menu.active .hemburgur span {
        background: #fff;
    }

    .navigation .row.align-items-end {
        display: flex;
        align-items: center !important;
    }

    .col-md-2.ml-40 {
        margin: 0 !important;
    }

    .col-md-2.d-flex.align-items-baseline.justify-content-center {
        justify-content: left !important;
    }

    .ethosbanner img {
        opacity: 0;
        display: none;
    }

    .slidepostion {
        left: 5em;
    }

    .vcontent h3:before {
        width: 50px;
        height: 1px;
    }

    .react-tabs__tab-list li {
        letter-spacing: 2px;
        font-size: 11px;
        white-space: nowrap;
        padding: 0 10px;
    }

    .heading {
        width: 85%;
        margin-left: 15%;
    }

    .header .container {
        max-width: 90%;
    }

    .App {
        width: 50%;
        float: left;
    }

    .navigation {
        margin: 0;
    }

    .ethosstn {
        position: static;
        transform: inherit;
        width: 100%;
        margin: 0;
    }

    .ethosbanner {
        text-align: center;
    }

    .ethoscont h3,
    .videocont h3 {
        width: 100%;
    }

    .videocont h3 {
        width: 100%;
        text-align: center;
        display: grid;
        align-items: center;
        justify-content: center;
    }

    img.mail,
    img.location {
        width: auto;
        height: auto;
    }

    .down-arrow {
        position: static;
        width: 100%;
        margin-top: 35px;
        display: none;
    }

    .ethoscont ul li:before {
        left: 50%;
        transform: translateX(-50%);
    }

    .videocont {
        transform: inherit;
        width: 100%;
        left: auto;
        top: auto;
        display: none;
        bottom: 30%;
    }

    .videostn img:last-child {
        top: 10%;
        right: 0%;
        margin-right: 0;
        margin-top: 0;
    }

    .video.position-relative {
        position: static !important;
    }

    .quote h2 {
        font-size: 22px;
        line-height: 30px;
    }

    .App:before {
        left: 25%;
        height: 2px;
        width: 50%;
        transform: rotate(-10deg);
        top: 0px;
    }

    .newsroomimg {
        margin-right: 10px;
    }

    .aboutus {
        margin-top: 50px;
    }

    .header.fixed {
        padding: 10px;
    }

    .business h3 {
        font-size: 15px;
        line-height: 50px;
        width: 65px;
        left: -20px;
    }

    .businessview {
        margin-left: 30px;
    }

    .wrap ul li:hover~li:last-child {
        width: 45px;
    }

    .ethos {
        height: auto;
        background-size: cover;
        padding: 40px 0;
        float: left;
    }

    :root {
        scroll-behavior: smooth;
        overflow-x: hidden;
    }

    .quote {
        width: 80%;
        margin-top: 10px;
    }

    section {
        padding: 25px 0;
    }

    .mbox {
        width: 120px;
        height: 120px;
    }

    .overview .heading h1 {
        display: inline-grid;
    }

    .react-tabs__tab-list {
        margin-bottom: 25px;
    }

    .react-tabs__tab-list {
        margin-bottom: 25px;
    }

    .vcontent {
        float: left;
        width: 100%;
        margin: 15px 0;
    }

    .click a:before {
        width: 25px;
        height: 25px;
        top: 50%;
        transform: translateY(-50%);
        left: -36px;
    }

    .click {
        display: flex;
        align-items: center;
        width: auto;
    }

    .business,
    .businessbanner,
    .businessview,
    .businessbanner img {
        height: 320px;
    }

    .csrdecp h3 {
        font-size: 22px;
        line-height: 30px;
    }

    .dot-img {
        left: -45%;
        text-align: right;
        right: auto;
        width: 63%;
    }

    .dot-img img {
        transform: rotateY(180deg);
    }

    #entertainement {
        position: relative;
    }

    .numbers.snapshot .count.d-flex {
        flex-wrap: wrap;
    }

    .bglayerbox {
        position: static;
        width: 150px;
        height: 150px;
    }

    .bg-layer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-left: 20%;
        bottom: auto;
        position: inherit;
    }

    .businessassoc .network {
        width: 100%;
    }

    .bg-layer .bglayerbox:nth-child(even) {
        margin-top: -50px;
    }

    .bg-layer .bglayerbox:nth-child(odd) {
        margin-top: 50px;
    }

    .circle06,
    .circle05,
    .circle08,
    .circle01 {
        opacity: 0;
    }

    .circle01 {
        bottom: 0;
        left: auto;
        top: auto;
        right: 0;
    }

    .circle07 {
        bottom: 10%;
        left: 10%;
        top: auto;
    }

    .circle02 {
        top: 10%;
        right: 0;
        left: auto;
    }

    .circle03 {
        left: 50%;
        top: 25%;
    }

    .circle04 {
        top: 41%;
        left: 60%;
    }

    .businessassoc {
        margin-bottom: 0;
        margin-top: 25px;
        background-image: url(./image/mbg-layer.webp);
        background-size: cover;
    }

    .contactbox {
        width: 50%;
        margin-bottom: 25px;
    }

    .contactdetail {
        flex-wrap: wrap;
    }

    .menu ul.d-flex.navbar li a.active {
        color: #ed1a3b;
    }

    .dot-img-left {
        display: none;
    }

    .content ul li,
    p,
    .vcontent p,
    .highlightpoint ul li span,
    .leaddetail p {
        font-size: 17px;
        line-height: 30px;
    }

    .business-associates .quote {
        margin: 0;
    }

    .hemburgur span:nth-child(2) {
        width: 70%;
        right: 0;
    }

    .hemburgur span:nth-child(3) {
        width: 50%;
        right: 0;
    }

    .contact-us {
        margin: 0;
    }

    .busilabel h4 span {
        font-size: 16px;
        line-height: 20px;
    }

    .form {
        margin: 0;
    }

    .footer .col-md-8.col-sm-6.col-xs-12 {
        width: 100%;
    }

    .footer-container .col-md-4.col-sm-6.col-xs-12 {
        padding-left: 0;
        margin-bottom: 25px;
    }

    .about-banner .desktop-show,
    .business-banner .desktop-show {
        display: block;
    }

    .about-banner .mobile-show,
    .business-banner .mobile-show {
        display: none;
    }


}

@media(max-width: 700px) {
    .journeycircle {
        margin: 140px 0;
    }

}

@media (max-width:630px) {
    .justify-space-between {
        justify-content: center;
    }

    .videostn img:last-child {
        width: 70px;
    }

    .leadership2 {
        flex-wrap: wrap;
    }

    .backbtns {
        bottom: inherit;
        top: 0;
    }

    .csrcont h4 {
        font-size: 18px;
    }

    .csrimg ul li span {
        font-size: 14px;
        line-height: 20px;
    }

    .csrimg {
        margin-bottom: 15px;
        padding-bottom: 15px;
        margin-top: 30px;
    }

    .businesscont a {
        width: 100%;
        float: left;
    }

    .backbtns i.backarrow,
    i.backarrow {
        width: 10px;
        height: 10px;
        margin-right: 5px;

    }

    .backbtns .btn-3 {
        border: none;
        margin-top: 10px;
    }

    .backbtns .btn-3 span {
        padding: 0;
    }

    .backbtns .btn-3 span::before {
        background: transparent;
    }

    .logo a img,
    .content,
    .leadershipimg,
    .leadership2:nth-child(1),
    .leadership2:nth-child(2) {
        width: 100%;
    }

    .sitembox {
        width: 100%;
    }

    .smap {
        flex-wrap: wrap;
        margin: 5%;
    }

    .leadershipcont {
        width: 100%;
        margin-top: 10px;
    }

    .proslider .slogo {
        height: 80px;
    }

    .backbtn .btn-3 {
        margin-right: 0;
    }

    .business-associates p {
        width: 100%;
    }

    .videostn img:first-child {
        height: 60vh;
    }

    .slidepostion {
        position: relative;
        left: 0;
    }

    .slogo {
        width: 90%;
        margin: 0px auto;
    }

    .ethoscont ul li em {
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-top: 10px;
    }

    .cicleimg img {
        width: 120px;
    }

    .col-12.row {
        margin: 0;
    }

    .react-tabs__tab-list {
        margin-bottom: 35px;
    }

    #numbers,
    section,
    .business-associates {
        padding: 25px 0;
    }

    .numberbanner {
        padding: 50px 0;
        background-position: right;
    }

    .bannercont h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .snapshot .numberbanner .App {
        width: 50%;
        float: left;
    }


    .snapshot .App:nth-child(odd):before {
        opacity: 0;
    }

    .header {
        padding: 15px 10px;
    }

    .values {
        margin-top: 0;
        padding: 3em 0;
        padding-bottom: 5em;
    }

    .heading h4 {
        font-size: 24px;
    }

    .heading h3 {
        font-size: 36px;
        line-height: 44px;
    }

    .jslider {
        width: 90%;
        margin: 0 5%;
    }

    .entertainmentlogo {
        justify-content: center;
        text-align: center;
        margin: 0px auto 30px;
    }

    .slick-slider button.slick-arrow.slick-prev {
        left: -50px;
    }

    .slick-slider button.slick-arrow.slick-next {
        right: -50px;
    }

    .col-md-5.col-xs-12.newsimg {
        margin-bottom: 20px;
    }

    .newsimg img {
        width: 100%;
        display: none;
    }

    .logoslider p {
        width: 90%;
    }

    .businessbox {
        margin: 0;
        display: grid !important;
    }

    .business {
        width: 100%;
        height: 100%;
        margin: 3px 0;
    }

    .business h3 {
        transform: inherit;
        position: static;
        padding: 0 15px;
        font-size: 22px;
        width: 100%;
        letter-spacing: 0.5px;
    }

    .businessview {
        margin: 0;
        width: 100%;
    }

    .business.active,
    .businessbody {
        width: 100%;
    }

    .businesslogo {
        text-align: center;
    }

    .businessbanner img {
        object-fit: cover;
        height: 100%;
    }

    .businessbody .col-md-6.col-xs-12 {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
    }

    .copyright .col-12.d-flex {
        justify-content: space-between;
    }

    .col-md-10.phone span {
        margin: 0;
    }

    .footer img.mail {
        margin-left: 0;
        width: 24px;
        height: 15px;
    }

    .wrap ul {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .wrap ul li {
        width: 100%;
        height: 50px;
    }

    .wrap ul li:hover,
    .wrap ul li:first-child~li:last-child {
        height: 400px;
        width: 100%;
    }

    .wrap ul li:hover~li:last-child {
        height: 50px;
    }

    .col-12.jseffect .business.active {
        height: 51vh;
        width: 100%;
    }

    .businessbody {
        width: 90%;
    }

    .col-12.jseffect .business {
        width: 100%;
        height: 50px;
    }

    .heading,
    .aboutcont {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }

    .proslider,
    .logoslider,
    .logoslider .container .container {
        width: 100%;
        max-width: 100%;
    }

    .footer-container .col-md-8.col-sm-6.col-xs-12.d-flex {
        width: 100%;
        margin-top: 25px;
        flex-wrap: wrap;
    }

    .footer-container .col-md-4.col-sm-6.col-xs-12:nth-child(1) {
        width: 100%;
        padding: 0;
    }

    .quote {
        width: 100%;
    }

    .contactdetails,
    .businessbody .row {
        margin: 0;
    }

    .contactdetails ul li:nth-child(1) {
        margin-bottom: 10px;
        padding-bottom: 10px;
    }


    .journey,
    .overview {
        margin: 0;
    }

    .ethosstn .row.col-12.m-0 {
        margin: 0 !important;
        width: 100%;
    }

    .footer h4 {
        margin-top: 20px;
    }

    .ethosbanner .title {
        margin-bottom: 25px;
    }

    .ethoscont h3 {
        font-size: 22px;
        line-height: 30px;
    }

    .businessbanner {
        height: 100%;
    }

    .businessview {
        height: 44.5vh;
    }

    .businessfooter {
        padding: 10px 30px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .businessbody {
        margin: 0;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .businesslogo img {
        width: 20%;
    }

    .busilabel {
        padding-bottom: 5px;
        margin-bottom: 5px;
        text-align: center;
    }

    /* Deepak Code Start */
    .addMore {
        float: none;
        width: 14% !important;
        margin-top: 0px;
        margin-right: 0px;
    }

    .top65 {
        top: 65%;
    }

    /* Deepak Code End */
    .entertainmentdtl {
        text-align: center;
    }

    .service .col-md-3.col-sm-6.col-xs-12 {
        margin: 15px 0;
        width: 50%;
    }

    .service p {
        margin: 8px 0 0 0;
    }

    .highlightpoint ul li {
        width: 45%;
        float: left;
        margin: 15px 0;
    }

    ul.d-flex.justify-content-center {
        display: inline-block !important;
    }

    .leadershipcont h4 {
        font-size: 12px;
        letter-spacing: 0.5px;
    }

    .csrcont {
        width: 100%;
    }

    .csrdecp {
        margin-top: 10px;
        text-align: center;
    }

    .businesslogo {
        width: 100%;
        margin: 0;
    }

    .multiLogo {
        justify-content: space-around;
        display: block;
    }

    .busilabel:before {
        transform: translateX(-50%);
        height: 2px;
    }

    .bg-layer .bglayerbox:nth-child(odd) {
        margin-top: 25px;
    }


    .bg-layer .bglayerbox:nth-child(even) {
        margin-top: -25px;
    }

    .bglayerbox {
        width: 120px;
        height: 120px;
    }

    .bg-layer {
        margin: 0;
        width: 70%;
        margin-left: 15%;
    }

    .partners-logo ul li {
        width: 24%;
    }

    .contactdetail:last-child .contactbox:last-child {
        border: none;
        padding: 0;
        margin: 0;
    }

    .contactbanner .banner {
        background-position: 90%;
    }

    .title {
        margin-bottom: 20px;
    }

    .leaddetail p {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 10px;
        letter-spacing: 0.5px;
    }

    .leaddetail .btn-3 {
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        margin-top: 50px;
        white-space: nowrap;

    }

    .numberbanner {
        background-attachment: inherit;
    }

    .highlightpoint ul li:last-child {
        margin-bottom: 0;
    }

    .service span {
        height: 80px;
    }
}

@media(max-width: 600px) {
    .real-est-ex-logo {
        justify-content: center;
    }

    .about-banner .desktop-show,
    .business-banner .desktop-show {
        display: none;
    }

    .about-banner .mobile-show,
    .business-banner .mobile-show {
        display: block;
    }

}

@media (max-width:480px) {
    .footer h4 {
        font-size: 19px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        text-align: left;
    }

    .socialmedia {
        width: 100%;
        margin-left: 0;
    }

    .csrimg ul li,
    .pomotionimg li {
        width: 100%;
    }

    .aboutus,
    .content h3 {
        margin-top: 15px;
    }

    #stemap {
        padding-top: 10px;
    }

    .smap {
        width: 90%;
        margin: 0 5%;
    }

    .contactdetail:nth-child(2) .contactbox ul {
        flex-wrap: wrap;
    }

    .contactdetail:nth-child(2) .contactbox ul li {
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .contactdetail:nth-child(2) .contactbox ul li:before {
        opacity: 1;
    }

    .contactbox ul li:last-child:before {
        opacity: 0;
    }

    .contactdetail:nth-child(2) .contactbox h2 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 25px;
    }

    .contactbox ul li:nth-child(3):before {
        opacity: 0 !important;
    }

    .leadership2:nth-child(1) .leadershipcont,
    .leadership2:nth-child(2) .leadershipcont {
        text-align: center;
    }

    .jslider .slick-slider .slick-list .slick-track .slick-slide.slick-current:nth-child(1) .journeybox:before,
    .journeybox:before,
    .journeybox.last-child:before {
        width: auto;
    }

    .highlightpoint ul.d-flex.justify-content-center {
        flex-wrap: wrap;
    }

    .slick-slider button {
        width: 30px;
        height: 30px;
    }

    .highlightpoint ul li {
        width: 50%;
        margin: 15px 0;
    }

    .partners-logo img {
        width: 90%;
    }

    .heading h3 {
        font-size: 30px;
        line-height: 36px;
    }


    .entertainmentdtl {
        text-align: center;
    }

    .entertainmentdtl ul li:before {
        left: 50%;
        transform: translate(-50%);
    }

    .col-md-5.col-sm-6.col-xs-12.d-flex .col-md-10 {
        width: 100%;
    }


    .csrcont {
        width: 90%;
    }

    .csr_descp .row:last-child {
        margin: 0;
    }

    .csr_descp .row {
        margin: 15px 0;
    }

    .footer {
        margin-top: 30px;
    }

    .csrdecp h3 {
        margin: 10px 0;
        text-align: center;
        font-size: 22px;
        line-height: 30px;
    }

    .banner .row {
        width: 100%;
        text-align: center;
        margin: 0;
    }

    .csrdecp p {
        text-align: center;
    }

    .numberbanner {
        padding: 30px 0;
        margin: 15px 0;
    }


    .newsroom {
        padding: 25px 0;
    }

    .quote h3 {
        margin-top: 0;
    }

    .slogo {
        width: 70%;
        margin: 0px auto;
    }

    .quote h3 {
        font-size: 14px;
        margin: 0;
    }

    .quote h3 span {
        font-size: 12px;
    }


    .snapshot .numberbanner {
        background-size: cover;
        background-position: center;
    }

    .header .container {
        max-width: 100%;
    }

    .menu ul.d-flex.navbar li a {
        font-size: 24px;
        line-height: 30px;
    }

    #quote {
        padding-top: 10px;
    }

    .App:first-child:before,
    .App:nth-child(2):before {
        opacity: 0;
    }

    .App:before {
        height: 1px;
    }

    a.btn-3.active {
        margin-left: 50px;
    }

    .proslider .slogo {
        height: 150px;
    }

    .contactdetails {
        margin-left: 0;
        width: 100%;
    }

    .contactdetails ul li strong {
        width: auto;
        margin-right: 10px;
    }

    .footer .col-md-4 h4,
    .footer .col-md-4 p {
        width: 100%;
        margin: 0;
    }

    .socialmedia {
        padding: 0;
    }

    .footer-container .col-md-8.col-sm-6.col-xs-12.d-flex {
        flex-wrap: wrap;
    }

    .dot-img {
        top: 80%;
    }

    .vcontent {
        text-align: center;
    }

    .vcontent h3:before {
        left: 50%;
        transform: translateX(-50%);
    }

    .slick-slider button.slick-arrow.slick-prev {
        left: 0;
    }

    .slick-slider button.slick-arrow.slick-next {
        right: 0;
    }

    .value .slogo {
        width: 90%;
    }

    .entertainmentdtl h2 {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 3px;
    }

    .entertainmentlogo {
        margin-bottom: 15px;
    }


    .logoslider .container {
        width: 100%;
    }

    .ethos.col-12 {
        margin-bottom: 0;
    }

    .bg-layer {
        width: 80%;
        margin-left: 10%;
        margin-top: 10%;
    }

    .bg-layer .bglayerbox:nth-child(even) {
        margin-top: -15px;
    }

    .bg-layer .bglayerbox:nth-child(odd) {
        margin-top: 15px;
    }

    .newsroom a.btn-3 {
        margin: 0px auto;
        width: auto;
        display: inline-block;
    }

    .col-md-12.btncenter {
        display: flex;
    }

    .contactbox {
        width: 100%;
        border-bottom: solid 1px #ededed;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .contactdetail {
        justify-content: center;
        margin: 0;
        border: none;
        padding: 0;
    }

    .breadcrum ul.d-flex {
        flex-wrap: wrap;
    }

    .breadcrum ul li {
        letter-spacing: 1px;
    }

    .service img {
        width: 50px;
    }

    .footer .col-md-4 h4 {
        margin-top: 25px;
        margin-bottom: 5px;
    }

    .dotimg {
        width: 60%;
        bottom: auto;
        top: 0;
        transform: rotateX(180deg);
        right: -30%;
    }

    #footer {
        overflow: hidden;
    }

    #header {
        height: 65px;
    }

    .navigation .col-4 {
        padding-right: 0;
    }

    .videostn {
        width: 100%;
    }

    .videostn img.mobile-show {
        width: 100%;
    }

    .contactdetail:nth-child(2) .contactbox ul li p {
        line-height: 26px;
    }

    .business:nth-child(5) .busilabel:before,
    .business:nth-child(4) .busilabel:before,
    .business:nth-child(6) .busilabel:before,
    .business:nth-child(8) .busilabel:before {
        opacity: 1;
    }

    .copyright .col-12.d-flex,
    .copyright ul.d-flex {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        padding: 5px;
    }

    .content ul li,
    p,
    .vcontent p,
    .highlightpoint ul li span,
    .leaddetail p {
        font-size: 16px;
    }

    .footer p {
        line-height: 24px;
        font-size: 15px;
    }
}

@media (max-width:420px) {
    .aboutus {
        margin: 0;
    }

    .contactdetail:nth-child(2) .contactbox ul {
        text-align: center;
        justify-content: center;
    }

    .contactbox {
        text-align: center;
    }

    .contactbox ul li p {
        width: 100%;
    }

    .contactbox .aligncenter {
        text-align: center;
    }

    .backbtns a.btn-3.active {
        margin-left: 0;
        margin-top: 0;
    }

    .backbtn.text-center {
        display: inline-grid;
        justify-content: center;
    }

    .web {
        display: flex;
        justify-content: center;
    }

    .react-tabs__tab-list {
        margin-bottom: 25px;
    }

    .busilabel h4 span {
        font-size: 18px;
        line-height: 26px;
    }

    .bg-layer {
        margin-left: 10%;
        margin-top: 10%;
        width: 80%;
    }

    .circle07 {
        width: 40px;
        height: 40px;
    }

    .busilabel h4 {
        font-size: 12px;
        line-height: 18px;
    }

    .ethoscont {
        float: left;
        width: 100%;
    }

    .valueslider .slogo {
        height: auto;
        border: none;
    }

    .slidepostion {
        top: -2.5em;
    }

    .values {
        padding: 2em 0;
    }

    .heading h3 {
        margin-top: 0;
    }

    .journeybox:before {
        background-color: #fff;
    }

    .careerlink {
        margin-top: 0;
    }

    .careerlink a.btn-3 span {
        letter-spacing: 1px;
    }

    .numberbanner {
        padding: 25px 0;
    }

    .entertainmentdtl h3 {
        font-size: 14px;
        line-height: 20px;
    }

    .bg-circle {
        width: 100px;
        height: 100px;
    }

    .csrdecp h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .csr_descp .row .col-xs-12 {
        padding: 0;
    }

    .App:before {
        top: -15px;
    }

    .countnum,
    .countnum h3 {
        font-size: 30px;
        line-height: 40px;
    }

    .csr_descp .row {
        margin: 0 0 30px 0;
    }

    .accordion .accordion-item .title:after {
        font-size: 20px;
        margin: 0;
    }

    .accordion-item {
        border-width: 1px !important;
        padding: 5px 0;
    }

    .accordion .accordion-item .title {
        letter-spacing: 0.3px;
    }


    .proslider .slogo {
        width: 80%;
        margin: auto;
    }


    .partners-logo ul li {
        width: 26%;
        margin: 0 2% 20px;
    }

    .contactdetails ul li p a:hover {
        color: #e85217;
    }

    .footer-container .col-md-8.col-sm-6.col-xs-12.d-flex {
        display: inline-block !important;
    }

    .contactdetails {
        text-align: center;
        margin-bottom: 20px;
    }


    .footer .col-md-4 h4,
    .footer .col-md-4 p {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        float: left;
    }

    .bglayerbox:last-child {
        left: 30%;
        position: relative;
    }

    .bannercont.pulll-center h2 {
        display: inline-block;
        font-size: 20px;
    }

    .entertainmentdtl h2 {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 24px;
    }

    .highlightpoint ul li {
        width: 100%;
        margin: 0 0 15px 0;
    }

    .videocont {
        height: 150px;
    }

    .leaddetail img {
        width: 150px;
        height: 150px;
        margin-right: 10px;
        margin-bottom: 0;
    }

    .leaddetail {
        margin-top: 20px;
    }

    .leaddetails h1 {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.5px;
        padding-left: 10px;
        border-width: 3px;
    }

    .leaddetails h2 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        padding-left: 10px;
        border-width: 3px;
    }
}

@media (max-width:375px) {
    .copyright .col-12.d-flex .col-md-6.col-xs-12 {
        width: 100%;
        float: left;
        flex-wrap: wrap;
    }

    .heading h1 {
        font-size: 26px;
        line-height: 34px;
    }

    .leadershipcont h2 {
        font-size: 18px;
        letter-spacing: 0.5px;
    }

    .leadershipcont ul li {
        letter-spacing: 0.5px;
        font-size: 10px;
        line-height: 16px;
    }

    .App:nth-child(2):before {
        opacity: 1;
    }

    .copyright .col-12.d-flex {
        flex-wrap: wrap;
    }

    .copyright ul {
        padding: 0;
        justify-content: center !important;
    }

    .businessassoc {
        margin: 0;
    }

    .contactdetails {
        margin-bottom: 25px;
    }

    .bglayerbox {
        height: 70px;
        width: 70px;
    }

    .circle07,
    .circle02 {
        width: 20px;
        height: 20px;
    }

    .business h3 {
        line-height: 40px;
        font-size: 18px;
    }

    .col-12.jseffect .business {
        height: 40px;
    }

    .App {
        width: 100%;
    }

    .footer .col-md-4 h4,
    .footer .col-md-4 p {
        margin-top: 10px;
        letter-spacing: 0.5px;
        font-size: 17px;
    }

    .service img {
        width: 40px;
    }

    .ethoscont h3 {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.5px;
    }

    .businessfooter {
        display: inline-block;
        text-align: center;
    }

    .businessfooter h4 {
        text-align: center;
        width: 100%;
        line-height: 20px;
    }

    .click {
        justify-content: center;
        margin: 0px auto;
        width: 120px;
        white-space: nowrap;
    }

    .col-12.jseffect .business.active {
        height: 100vh;
    }

    .businessview {
        height: 94vh;
    }

    .businessbody .col-md-6.col-xs-12 {
        width: 100%;
    }

    .businesslogo img {
        width: 40%;
    }

    .businessbody {
        margin-bottom: 50px;
    }

    .businessbody .col-md-6:nth-child(3) .busilabel,
    .businessbody .col-md-6:nth-child(4) .busilabel {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .journeybox {
        /* height: 70vh; */
    }


    .busilabel h4 span {
        margin-top: 0;
    }

    .busilabel:before,
    .businessbody .col-md-6:nth-child(3) .busilabel:before {
        opacity: 1;
    }

    .click a {
        line-height: 30px;
    }

    .about .logo img {
        width: 100px;
    }

    .contactbox,
    .contactdetail:nth-child(2) .contactbox {
        width: 90%;
    }

    .cicleimg img {
        width: 100px;
    }

    .bg-layer {
        width: 60%;
        margin: 0 20%;
        margin-top: 10%;
    }

    .leadership2:nth-child(1) {
        margin-top: 0;
    }

    .click a:before {
        left: -15px;
        width: 20px;
        height: 20px;
    }

    .content ul li,
    p,
    .vcontent p,
    .highlightpoint ul li span,
    .leaddetail p {
        font-size: 15px;
        line-height: 26px;
    }

    .leaddetail .btn-3 span {
        padding-right: 0;
    }
}

@media (max-width:340px) {
    .partners-logo ul li {
        width: 40%;
    }

    i.backarrow {
        margin-right: 5px;
        width: 20px;
        height: 15px;
    }

    .leaddetail .btn-3 span {
        padding: 5px;
        line-height: 30px;
    }

    .leaddetail img {
        width: 120px;
        height: 120px;
    }

    .leadershipcont h2 {
        font-size: 15px;
        line-height: 20px;
    }

    .busilabel h4 {
        font-size: 11px;
        letter-spacing: 0.5px;
    }

    .busilabel h4 span {
        font-size: 16px;
        line-height: 22px;
    }

    .banner {
        height: 150px;
    }

    .bg-layer {
        width: 70%;
        margin: 0 15%;
        margin-top: 10%;
    }

    .entertainmentlogo {
        width: 150px;
        height: 150px;
        margin-bottom: 25px;
    }

    .react-tabs__tab-list li {
        margin: 0 5px;
        line-height: 26px;
    }

    .entertainmentdtl h2 {
        margin-bottom: 5px;
    }

    .quote h2 {
        font-size: 16px;
        line-height: 24px;
    }

    .videocont h3 span {
        font-size: 24px;
        line-height: 30px;
    }

    .title h2,
    .title h1 {
        font-size: 12px;
        letter-spacing: 5px;
    }

    .aboutcont {
        margin-top: 10px;
    }

    .proslider .slogo {
        height: 100px;
        width: 72%;
    }

    .about .logo img {
        width: 100px;
    }

    .snapshot .numberbanner .App,
    .snapshot .numberbanner .App:nth-child(even) {
        width: 100%;
        margin: 10px 0;
    }

    .snapshot .App:before {
        opacity: 1 !important;
        transform: rotate(75deg);
        left: 50%;
        top: 70%;
    }

    .snapshot .App:last-child:before {
        opacity: 0 !important;
    }

    .title {
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .bannercont h2 {
        font-size: 20px;
        text-align: center;
    }

    .bannercont {
        width: 100%;
        margin: 0;
    }

    .businessfooter {
        padding: 5px 10px;
    }

    .dot-img-left img,
    .dot-img img {
        width: 30%;
    }

    .journeycircle h5 {
        width: 100px;
        height: 100px;
        font-size: 30px;
        line-height: 40px;
    }

    .bg-circle {
        width: 70px;
        height: 70px;
    }

    .sm-circle {
        top: 5em;
    }

    .menu ul.d-flex.navbar li a {
        font-size: 17px;
        line-height: 26px;
    }

    .menu ul.d-flex.navbar li {
        padding: 5px 0;
    }

    .header {
        padding: 10px 5px;
    }

    #header {
        height: 65px;
    }

    .hemburgur {
        width: 30px;
        height: 20px;
    }

    .hemburgur span:nth-child(2) {
        top: 8px;
    }

    .hemburgur span:nth-child(3) {
        top: 16px;
    }

    .content ul li,
    p,
    .ethoscont ul li,
    .entertainmentdtl p,
    .entertainmentdtl ul li,
    .leaddetail p {
        font-size: 14px;
        line-height: 20px;
    }

    .wrap ul li:hover,
    .wrap ul li:first-child~li:last-child {
        height: 320px;
    }

    .wrap ul li:hover~li:last-child {
        height: 50px;
    }

    .heading h1 {
        font-size: 26px;
        line-height: 34px;
    }

    .heading {
        margin-bottom: 10px;
    }

    .react-tabs__tab-panel p {
        margin-bottom: 10px;
    }

}