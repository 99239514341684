@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Medium.woff2') format('woff2'),
        url('Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Medium.woff2') format('woff2'),
        url('Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Light.woff2') format('woff2'),
        url('Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-LightItalic.woff2') format('woff2'),
        url('Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-XLightItalic.woff2') format('woff2'),
        url('Gotham-XLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-MediumItalic.woff2') format('woff2'),
        url('Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Thin.woff2') format('woff2'),
        url('Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-UltraItalic.woff2') format('woff2'),
        url('Gotham-UltraItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-ThinItalic.woff2') format('woff2'),
        url('Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-XLight.woff2') format('woff2'),
        url('Gotham-XLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('Jost-Regular.woff2') format('woff2'),
        url('Jost-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Black.woff2') format('woff2'),
        url('Gotham-Black.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('Jost-Italic.woff2') format('woff2'),
        url('Jost-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Carnero';
    src: url('Carnero-Bold.woff2') format('woff2'),
        url('Carnero-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Bold.woff2') format('woff2'),
        url('Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Bold.woff2') format('woff2'),
        url('Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Light.woff2') format('woff2'),
        url('Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Book.woff2') format('woff2'),
        url('Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-BookItalic.woff2') format('woff2'),
        url('Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-BoldItalic.woff2') format('woff2'),
        url('Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-BookItalic.woff2') format('woff2'),
        url('Gotham-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Franklin Gothic Book';
    src: url('FranklinGothic-Book.woff2') format('woff2'),
        url('FranklinGothic-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Franklin Gothic Demi';
    src: url('FranklinGothic-Demi.woff2') format('woff2'),
        url('FranklinGothic-Demi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Franklin Gothic Medium Cond';
    src: url('FranklinGothic-MediumCond.woff2') format('woff2'),
        url('FranklinGothic-MediumCond.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

